import { EngineActionTypes } from "../constants/engine-action-types";

const DefaultState = {
  loading: false,
  data: [],
  errorMsg: "",
};

const TargetLanguageListReducer = (state = DefaultState, action) => {
  switch (action.type) {
    case EngineActionTypes.AVAILABLE_TARGET_LANGUAGES_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case EngineActionTypes.AVAILABLE_TARGET_LANGUAGES_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: "Unable to fetch target languages",
      };
    case EngineActionTypes.AVAILABLE_TARGET_LANGUAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: "",
      };
    case EngineActionTypes.AVAILABLE_TARGET_LANGUAGES_RESET:
      return DefaultState;
    default:
      return state;
  }
};

export default TargetLanguageListReducer;
