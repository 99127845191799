import { ConfigurationActionTypes } from "../constants/configuration-action-types";

const DefaultState = {
  status: "idle",
};

const ConfigurationUpdateReducer = (state = DefaultState, action) => {
  switch (action.type) {
    case ConfigurationActionTypes.CONFIGURATION_UPDATE_LOADING:
      return {
        ...state,
        status: "loading",
      };
    case ConfigurationActionTypes.CONFIGURATION_UPDATE_SUCCESS:
      return {
        ...state,
        status: "success",
      };
    case ConfigurationActionTypes.CONFIGURATION_UPDATE_FAILED:
      return {
        ...state,
        loading: false,
        status: "failed",
      };

    case ConfigurationActionTypes.CONFIGURATION_UPDATE_IDLE:
      return DefaultState;

    default:
      return state;
  }
};

export default ConfigurationUpdateReducer;
