export const AuthActionTypes = {
  GOOGLE_AUTH_LOADING: "AUTH/GOOGLE_AUTH_LOADING",
  GOOGLE_AUTH_SUCCESS: "AUTH/GOOGLE_AUTH_SUCCESS",
  GOOGLE_AUTH_FAIL: "AUTH/GOOGLE_AUTH_FAIL",

  LOGOUT: "AUTH/LOGOUT",

  GET_USER_LOADING: "AUTH/GET_USER_LOADING",
  GET_USER_SUCCESS: "AUTH/GET_USER_SUCCESS",
  GET_USER_FAIL: "AUTH/GET_USER_FAIL",

  HAS_USER_ACCEPTED_TOS_LOADING: "AUTH/HAS_USER_ACCEPTED_TOS_LOADING",
  HAS_USER_ACCEPTED_TOS_SUCCESS: "AUTH/HAS_USER_ACCEPTED_TOS_SUCCESS",
  HAS_USER_ACCEPTED_TOS_FAIL: "AUTH/HAS_USER_ACCEPTED_TOS_FAIL",

  ACCEPT_TOS_LOADING: "AUTH/ACCEPT_TOS_LOADING",
  ACCEPT_TOS_SUCCESS: "AUTH/ACCEPT_TOS_SUCCESS",
  ACCEPT_TOS_FAIL: "AUTH/ACCEPT_TOS_FAIL",

  CHANGE_TUTORIAL_STATE_LOADING: "AUTH/CHANGE_TUTORIAL_STATE_LOADING",
  CHANGE_TUTORIAL_STATE_SUCCESS: "AUTH/CHANGE_TUTORIAL_STATE_SUCCESS",
  CHANGE_TUTORIAL_STATE_FAILED: "AUTH/CHANGE_TUTORIAL_STATE_FAILED",

  AUTH_LOGIN_LOADING: "AUTH/AUTH_LOGIN_LOADING",
  AUTH_LOGIN_SUCCESS: "AUTH/AUTH_LOGIN_SUCCESS",
  AUTH_LOGIN_FAILED: "AUTH/AUTH_LOGIN_FAILED",

  AUTH_REGISTER_LOADING: "AUTH/AUTH_REGISTER_LOADING",
  AUTH_REGISTER_SUCCESS: "AUTH/AUTH_REGISTER_SUCCESS",
  AUTH_REGISTER_FAILED: "AUTH/AUTH_REGISTER_FAILED",
  AUTH_REGISTER_NOT_INVITED: "AUTH/AUTH_REGISTER_NOT_INVITED",

  MESSAGE_REGISTRATION_SUCCESS: "AUTH/MESSAGE_REGISTRATION_SUCCESS",
  MESSAGE_REGISTRATION_NOT_INVITED: "AUTH/MESSAGE_REGISTRATION_NOT_INVITED",
  MESSAGE_PASSWORD_RESET_SUCCESS: "AUTH/MESSAGE_PASSWORD_RESET_SUCCESS",
  MESSAGE_FORGOT_PASSWORD: "AUTH/MESSAGE_FORGOT_PASSWORD",

  AUTH_RESET_PASSWORD_LOADING: "AUTH/AUTH_RESET_PASSWORD_LOADING",
  AUTH_RESET_PASSWORD_SUCCESS: "AUTH/AUTH_RESET_PASSWORD_SUCCESS",
  AUTH_RESET_PASSWORD_FAILED: "AUTH/AUTH_RESET_PASSWORD_FAILED",

  AUTH_FORGOT_PASSWORD_LOADING: "AUTH/AUTH_FORGOT_PASSWORD_LOADING",
  AUTH_FORGOT_PASSWORD_SUCCESS: "AUTH/AUTH_FORGOT_PASSWORD_SUCCESS",
  AUTH_FORGOT_PASSWORD_FAILED: "AUTH/AUTH_FORGOT_PASSWORD_FAILED",

  AUTH_LOGIN_IP_SUCCESS: "AUTH/AUTH_LOGIN_IP_SUCCESS",
  AUTH_LOGIN_IP_LOADING: "AUTH/AUTH_LOGIN_IP_LOADING",
  AUTH_LOGIN_IP_FAILED: "AUTH/AUTH_LOGIN_IP_FAILED",

  GET_INVITED_USERS_SUCCESS: "AUTH/GET_INVITED_USERS_SUCCESS",
  GET_INVITED_USERS_LOADING: "AUTH/GET_INVITED_USERS_LOADING",
  GET_INVITED_USERS_FAILED: "AUTH/GET_INVITED_USERS_FAILED",
};
