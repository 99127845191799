import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ClearConfigurationViews,
  ModifyNewConfigurationName,
  SaveConfiguration,
} from "../../actions/configurationActions";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import {
  ConfigurationSelectedEngines,
  getAddEngines,
  getConfigurationName,
  SaveConfigurationButton,
} from "./ConfigurationShared";

const ConfigurationCreate = () => {
  const configurationCreateState = useSelector(
    (state) => state.configurationCreate
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef(null);

  React.useEffect(() => {
    dispatch(ClearConfigurationViews());
  }, [dispatch]);

  /**
   * Show a toast message
   */
  React.useEffect(() => {
    if (
      configurationCreateState.success === undefined ||
      configurationCreateState.success === null
    ) {
      return;
    }
    // Error
    if (!configurationCreateState.success) {
      // FIXME toast.current.replace does not work correctly with the ZIndex
      toast.current.show({
        severity: "error",
        summary: "Configuration creation failed.",
        detail: configurationCreateState.msg
          ? configurationCreateState.msg
          : "",
        life: 10000,
        id: "configurationCreateFailed",
      });
    }

    if (configurationCreateState.success) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Configuration saved successfully",
        id: "configurationCreateSuccess",
      });

      // Try to navigate to the new configuration
      if (configurationCreateState.id) {
        // Navigate to the new configuration details page
        navigate("/config/" + configurationCreateState.id);
      } else {
        // Navigate back to the configuration list
        navigate("/config");
      }
    }
  }, [configurationCreateState, navigate]);

  const SaveConfigurationClick = async (name, engines) => {
    dispatch(SaveConfiguration(name, engines));
  };

  return (
    <div className={"p-3"}>
      <Toast ref={toast} />
      <h1>Create new configuration</h1>

      {getConfigurationName(
        (e) => dispatch(ModifyNewConfigurationName(e.target.value)),
        "Configuration name... (required)",
        false
      )}

      {ConfigurationSelectedEngines(configurationCreateState, dispatch)}
      {getAddEngines()}

      {SaveConfigurationButton(
        "Save configuration",
        () =>
          SaveConfigurationClick(
            configurationCreateState.name,
            configurationCreateState.data
          ),
        !configurationCreateState.name.trim().length > 0
      )}
    </div>
  );
};

export default ConfigurationCreate;
