import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Ripple } from "primereact/ripple";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetAdvisory,
  GetTestSet,
  ResetTestSetTranslations,
} from "../../actions/advisoryActions";
import {
  GetAvailableDomains,
  GetAvailableProviders,
  GetAvailableSourceLanguages,
  GetAvailableTargetLanguages,
  GetEnginesList,
} from "../../actions/engineActions";
import { EngineActionTypes } from "../../constants/engine-action-types";
import { sourceLanguageFlagTemplate } from "../../Helpers";
import api from "../../interceptors/api";
import { uploadedFilePreprocessor } from "../utils/fileUpload";
import ProgressBarAdvisory from "./ProgressBarAdvisory";

const EngineAdvisory = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [modeSourceRefAdvisory, setModeSourceRefAdvisory] = useState(true);

  const engines = useSelector((state) => state.engineList);
  const availableSourceLanguages = useSelector(
    (state) => state.availableSourceLanguages,
  );
  const availableTargetLanguages = useSelector(
    (state) => state.availableTargetLanguages,
  );
  const availableProviders = useSelector((state) => state.availableProviders);
  const availableDomains = useSelector((state) => state.availableDomains);

  const [selectedSourceLanguage, setSelectedSourceLanguage] = useState(null);
  const [selectedTargetLanguage, setSelectedTargetLanguage] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState(null);

  const [currentAdvisoryId, setCurrentAdvisoryId] = useState(null);

  const [currentTestsetId, setCurrentTestsetId] = useState(null);
  const [selectedEngines, setSelectedEngines] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  const toast = useRef(null);
  const uploadRef = useRef(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [validTestSet, setValidTestSet] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);

  const MIN_SEGMENTS = Number(window._env_.TEST_SET_MIN_SEGMENTS || 50);
  const MAX_SEGMENTS = Number(window._env_.TEST_SET_MAX_SEGMENTS || 1000);
  const MAX_ENGINES = 10;

  /** In accordance with https://confluence-server.crosslang.com/display/MTG/Engine+Advisory
   * S = 10 euros cost * 2 bytes per character / 10 engines / 10 euro per 1 000 000 characters = 200 000 bytes
   * [mtg-815] After internal discussion, increased to 10 MB to play safe and please the users
   */
  const MAX_FILESIZE = Number(window._env_.MAX_FILESIZE || 10000000);

  const resetState = useCallback(() => {
    availableSourceLanguages.data = null;
    availableTargetLanguages.data = null;
    // Reset available languages
    dispatch({
      type: EngineActionTypes.AVAILABLE_SOURCE_LANGUAGES_SUCCESS,
      data: [],
    });
    dispatch({
      type: EngineActionTypes.AVAILABLE_TARGET_LANGUAGES_SUCCESS,
      data: [],
    });

    engines.data = null;
    // Reset engines
    dispatch({
      type: EngineActionTypes.ENGINE_LIST_SUCCESS,
      payload: { results: [], count: 0 },
    });

    setCurrentAdvisoryId(null);
    setCurrentTestsetId(null);

    setActiveStep(0);
    setUploadedFile(null);

    dispatch(ResetTestSetTranslations());
  }, [dispatch, availableSourceLanguages, availableTargetLanguages]);

  const loadSourceLanguages = useCallback(
    (testsetId) => {
      dispatch(GetAvailableSourceLanguages(testsetId));
    },
    [dispatch],
  );

  const loadTargetLanguages = useCallback(
    (testsetId, source) => {
      dispatch(GetAvailableTargetLanguages(testsetId, source));
    },
    [dispatch],
  );

  const loadDomains = useCallback(() => {
    dispatch(
      GetAvailableDomains(
        selectedSourceLanguage,
        selectedTargetLanguage,
        selectedProvider,
        true,
      ),
    );
  }, [
    dispatch,
    selectedSourceLanguage,
    selectedTargetLanguage,
    selectedProvider,
  ]);

  const loadProviders = useCallback(() => {
    console.log("callback loadProviders");

    dispatch(
      GetAvailableProviders(
        selectedSourceLanguage,
        selectedTargetLanguage,
        selectedDomain,
        true,
      ),
    );
  }, [
    dispatch,
    selectedSourceLanguage,
    selectedTargetLanguage,
    selectedDomain,
  ]);

  const loadEngineListAllParameters = useCallback(() => {
    let engine_query = {};
    if (selectedSourceLanguage) {
      engine_query["source_language"] = { value: selectedSourceLanguage };
    }
    if (selectedTargetLanguage) {
      engine_query["target_language"] = { value: selectedTargetLanguage };
    }
    if (selectedProvider) {
      engine_query["provider"] = { value: selectedProvider };
    }
    if (selectedDomain) {
      engine_query["domain"] = { value: selectedDomain };
    }
    engine_query["exact_match"] = { value: true };

    if (selectedSourceLanguage && selectedTargetLanguage) {
      dispatch(GetEnginesList(5, 0, 0, engine_query, "", null, null));
    }
  }, [
    selectedSourceLanguage,
    selectedTargetLanguage,
    selectedProvider,
    selectedDomain,
    dispatch,
  ]);

  /**
   * On page load: reset the state.
   * WARNING: Leave the dependency array empty. When we include dispatch and resetState in the dependency array,
   * the page will reset the state on every render.
   * This shouldn't happen because dispatch and resetState are stable references, but it does.
   * So we have to ignore the warning.
   */
  useEffect(() => {
    resetState();

    // When leaving the page, and the component is unmounted, also reset the state.
    return () => {
      resetState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Validate testset on available language codes: Check if engines are found
  useEffect(() => {
    if (currentTestsetId == null) {
      return;
    }

    // Wait till done loading
    if (availableSourceLanguages.loading || availableTargetLanguages.loading) {
      return;
    }

    // Check if any of the languages are found, if not show the error
    if (
      availableSourceLanguages.errorMsg ||
      availableTargetLanguages.errorMsg ||
      (availableSourceLanguages.data &&
        availableSourceLanguages.data.length === 0) ||
      (availableTargetLanguages.data &&
        availableTargetLanguages.data.length === 0)
    ) {
      // throw error
      toast.current.show({
        severity: "error",
        summary: "No engines available",
        detail: t("test_set.error.NO_ENGINES_AVAILABLE"),
        life: 5000,
      });
      setLoadingFile(false);
      setUploadedFile(null);
      uploadRef.current.clear();

      resetState();
    }

    if (
      availableSourceLanguages.data &&
      availableSourceLanguages.data.length > 0 &&
      availableTargetLanguages.data &&
      availableTargetLanguages.data.length > 0
    ) {
      // Enable the next step
      let detail_type = modeSourceRefAdvisory ? "Test set" : "Test file";
      toast.current.show({
        severity: "success",
        summary: `${detail_type} uploaded`,
        detail: `Click next to select the languages and engines.`,
      });
      setValidTestSet(true);
    }
  }, [
    currentTestsetId,
    availableSourceLanguages,
    availableTargetLanguages,
    t,
    resetState,
  ]);

  // Start loading settings after the test-set is loaded
  // # Menu logic: First select source, this will update the target languages. This in turn updates the domains and providers
  useEffect(() => {
    if (currentTestsetId !== null) {
      loadSourceLanguages(currentTestsetId);
    }
  }, [loadSourceLanguages, currentTestsetId]);

  // Set selected source language to first available
  useEffect(() => {
    if (
      availableSourceLanguages.data &&
      availableSourceLanguages.data.length > 0
    ) {
      setSelectedSourceLanguage(availableSourceLanguages.data[0].value);
    } else {
      setSelectedSourceLanguage(null);
    }
  }, [availableSourceLanguages.data]);

  // Set selected target language to first available
  useEffect(() => {
    if (
      availableTargetLanguages.data &&
      availableTargetLanguages.data.length > 0 &&
      modeSourceRefAdvisory
    ) {
      setSelectedTargetLanguage(availableTargetLanguages.data[0].value);
    } else {
      setSelectedTargetLanguage(null);
    }
  }, [availableTargetLanguages.data]);

  // Update Target languages list when source language is selected (Should only change once, since the source language is fixed)
  useEffect(() => {
    if (currentTestsetId !== null && selectedSourceLanguage !== null) {
      loadTargetLanguages(currentTestsetId, selectedSourceLanguage);
    }
  }, [selectedSourceLanguage, loadTargetLanguages, currentTestsetId]);

  const uploadTestsetReady = useCallback(() => {
    return currentTestsetId !== null && validTestSet;
  }, [currentTestsetId, validTestSet]);

  // Load domain and provider list when source and target language change
  useEffect(() => {
    if (uploadTestsetReady()) {
      loadDomains();
    }
  }, [loadDomains, currentTestsetId, validTestSet, uploadTestsetReady]);
  useEffect(() => {
    if (uploadTestsetReady()) {
      loadProviders();
    }
  }, [loadProviders, currentTestsetId, validTestSet, uploadTestsetReady]);

  // # Update the engine list
  useEffect(() => {
    if (uploadTestsetReady()) {
      loadEngineListAllParameters();
    }
  }, [
    loadEngineListAllParameters,
    currentTestsetId,
    validTestSet,
    uploadTestsetReady,
  ]);

  // # Dropdown menu on change functions:

  const clearSelected = () => {
    // Clear selected engines
    setSelectedEngines([]);

    // Reset selected domain and provider
    setSelectedProvider(null);
    setSelectedDomain(null);
  };

  const onSourceLanguageChange = (e) => {
    clearSelected();
    setSelectedSourceLanguage(e.value);
  };

  const onTargetLanguageChange = (e) => {
    clearSelected();
    setSelectedTargetLanguage(e.value);
  };

  const onProviderChange = (e) => {
    if (e.value === undefined) {
      e.value = null;
    }
    setSelectedProvider(e.value);
  };

  const onDomainChange = (e) => {
    if (e.value === undefined) {
      e.value = null;
    }
    setSelectedDomain(e.value);
  };

  const onPage = (event) => {
    dispatch(
      GetEnginesList(event.rows, event.page, event.first, engines.query, ""),
    );
  };

  const onAdvisoryModeChange = (isSourceRefMode) => {
    setModeSourceRefAdvisory(isSourceRefMode);
    setUploadedFile(null);
    uploadRef.current.clear();

    resetState();
  };

  const getFormConfig = () => {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  };

  const fileUploader = async (event) => {
    const files = event.files;

    if (!files?.length) {
      return;
    }

    if (uploadedFile) {
      let detail_type = modeSourceRefAdvisory ? "Test set" : "Test file";
      toast.current.show({
        severity: "error",
        summary: `${detail_type} already uploaded`,
        detail: "Please upload a new file or clear the current file.",
      });
      return;
    }

    const file = uploadedFilePreprocessor(files[0]);

    setLoadingFile(true);
    setValidTestSet(false);
    setUploadedFile(file);
    let testSetFormData = new FormData();
    if (!modeSourceRefAdvisory) {
      testSetFormData.append("source_based", "True");
    }
    // CREATE ADVISORY
    await api
      .post(
        `/engine_advisory/api/engine_advisories`,
        testSetFormData,
        getFormConfig(),
      )
      .then((res) => {
        const advisoryId = res.data.id;
        setCurrentAdvisoryId(advisoryId);
        return advisoryId;
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while creating advisory.",
        });
        setLoadingFile(false);
        setUploadedFile(null);
        uploadRef.current.clear();
        return Promise.reject(err);
      })
      .then(async (advisoryId) => {
        testSetFormData = new FormData();
        if (!modeSourceRefAdvisory) {
          // EXTRACT TEXT FROM TEST FILE FOR SOURCE-BASED ADVISORY
          let extractTextFormData = new FormData();
          extractTextFormData.append("file", file);
          await api
            .post(
              "/catalogue/api/extract-text",
              extractTextFormData,
              getFormConfig(),
            )
            .then((res) => {
              const blob = new Blob([res.data.text], {
                type: "text/plain",
              });
              testSetFormData.append("source_based_source", file);
              testSetFormData.append("source", blob, "source.txt");
              testSetFormData.append(
                "source_language",
                res.data.detected_language,
              );
            })
            .catch((err) => {
              console.log(err.response.data);
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Error while extracting text from file.",
              });
              setLoadingFile(false);
              setUploadedFile(null);
              uploadRef.current.clear();
              return Promise.reject(err);
            });
        } else {
          testSetFormData.append("source_reference", file);
        }
        testSetFormData.append("engine_advisory", advisoryId);
        // CREATE TEST SET
        await api
          .post(
            `/engine_advisory/api/test_sets`,
            testSetFormData,
            getFormConfig(),
          )
          .then((res) => {
            const testsetId = res.data.id;
            // TODO Test if this is still needed, you instead get data from this res
            dispatch(GetTestSet(testsetId));

            return testsetId;
          })
          .catch((err) => {
            console.log(err.response.data);
            let detail_type = modeSourceRefAdvisory ? "Test set" : "Test file";
            toast.current.show({
              severity: "error",
              summary: `${detail_type} upload failed`,
              detail: verifyTestSetErrorMessage(err),
              life: 10000,
            });
            setLoadingFile(false);
            setUploadedFile(null);
            uploadRef.current.clear();
            return Promise.reject(err);
          })
          .then(async (testsetId) => {
            // VERIFY TEST SET
            testSetFormData = new FormData();
            testSetFormData.append("id", testsetId);
            await api
              .post(
                `/engine_advisory/api/verify/test_set`,
                testSetFormData,
                getFormConfig(),
              )
              .then(async (res) => {
                setLoadingFile(false);

                // Verifies the advisory (and test files) are valid
                dispatch(GetAdvisory(advisoryId));

                setCurrentTestsetId(testsetId);
              })
              .catch((err) => {
                toast.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: verifyTestSetErrorMessage(err),
                  life: 5000,
                });
                console.log(err.response.data);
                setLoadingFile(false);
                setUploadedFile(null);
                uploadRef.current.clear();
                return Promise.reject(err);
              });
          });
      });
  };

  const verifyTestSetErrorMessage = (err) => {
    const error = err.response.data.error;
    const details = err.response.data.details;
    switch (error) {
      case "NO_SOURCE_LANGUAGE":
        return t("test_set.error.NO_SOURCE_LANGUAGE");
      case "NO_TARGET_LANGUAGE":
        return t("test_set.error.NO_TARGET_LANGUAGE");
      case "NO_SOURCE_TARGET_LANGUAGE":
        return t("test_set.error.NO_SOURCE_TARGET_LANGUAGE");
      case "TEST_SET_SIZE_TOO_SMALL":
        return t("test_set.error.TEST_SET_SIZE_TOO_SMALL", {
          min_segments: details.min_segments,
          max_segments: details.max_segments,
        });
      case "TEST_SET_SIZE_TOO_LARGE":
        return t("test_set.error.TEST_SET_SIZE_TOO_LARGE", {
          min_segments: details.min_segments,
          max_segments: details.max_segments,
        });
      case "TEST_SET_FORMAT_NOT_SUPPORTED":
        return t("test_set.error.TEST_SET_FORMAT_NOT_SUPPORTED", {
          allowed_formats: details.allowed_formats,
        });
      case "TEST_SET_TMX_LANG_ATTRIBUTE_MISSING":
        return t("test_set.error.TEST_SET_TMX_LANG_ATTRIBUTE_MISSING", {
          lang_attribute: details.lang_attribute,
        });
      default:
        return t("test_set.error.UNKNOWN");
    }
  };

  const onTemplateRemove = (file, callback) => {
    setUploadedFile(null);
    callback();
    uploadRef.current.clear();

    resetState();
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString("en-GB")}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-rounded p-button-danger p-button-text ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const confirmLaunchAdvisory = () => {
    confirmDialog({
      styleClass: "confirm-advisory-dialog",
      message:
        "Your account will be billed once the advisory is launched. Click 'Launch' to continue.",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Launch",
      rejectLabel: "Cancel",
      accept: () => {
        launchAdvisory();
      },
      reject: () => {
        toast.current.show({
          severity: "warn",
          summary: "Cancelled",
          detail: "The automated engine advisory was not launched.",
          life: 3000,
        });
      },
    });
  };

  const launchAdvisory = async () => {
    // CREATE TEST SET TRANSLATION FOR EVERY SELECTED ENGINE
    // Filter duplicate engines based on engine.id
    let selectedEnginesFiltered = selectedEngines.filter(
      (engine, index, self) =>
        index === self.findIndex((t) => t.id === engine.id),
    );

    if (!modeSourceRefAdvisory) {
      const targetLanguage =
        selectedEnginesFiltered[0].target_language_base_code;
      // update target language for test file
      let bodyFormData = new FormData();
      bodyFormData.append("target_language", targetLanguage);
      await api
        .put(
          `/engine_advisory/api/test_set/${currentTestsetId}`,
          bodyFormData,
          getFormConfig(),
        )
        .then((res) => {
          console.log("Updated test file target language");
        });
    }

    for (const selectedEngine of selectedEnginesFiltered) {
      let bodyFormData = new FormData();
      bodyFormData.append("test_set", currentTestsetId);
      bodyFormData.append("engine", selectedEngine.id);
      await api
        .post(
          `/engine_advisory/api/test_set_translations`,
          bodyFormData,
          getFormConfig(),
        )
        .then((res) => {
          return res.data.id;
        });
    }
    // LAUNCH ADVISORY

    try {
      let bodyFormData = new FormData();
      bodyFormData.append("id", currentAdvisoryId);
      await api.post(
        `/engine_advisory/api/launch`,
        bodyFormData,
        getFormConfig(),
      );

      // Launched successfully, continue to next step.
      toast.current.show({
        severity: "success",
        summary: "Confirmed",
        detail: "The engine advisory is launched.",
        life: 3000,
      });
      setActiveStep(2);
    } catch (err) {
      toast.current.show({
        severity: "error",
        detail:
          "Unable to launch advisory, please try again later or contact support.",
        life: 10000,
        id: "advisoryLaunchFailed",
      });
      // Launch failed, reset the state
      resetState();
    }
  };

  /**
   * Navigate to details page when EA has launched successfully
   */
  useEffect(() => {
    if (activeStep >= 2) {
      navigate(`/engine-advisory/automated/${currentAdvisoryId}`, {
        state: { step: activeStep },
      });
    }
  }, [activeStep]);

  const nextStepDisabled = () => {
    if (activeStep === 0) {
      return !uploadTestsetReady();
    }
    return true;
  };

  const isEngineSelectable = (event) => {
    // After adding, you should add up to a maximum of MAX_ENGINES
    return selectedEngines.length < MAX_ENGINES;
  };

  return (
    <div>
      <ProgressBarAdvisory activeStep={activeStep} max_engines={MAX_ENGINES} />
      <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
        {activeStep === 0 && (
          <div className="surface-card p-4 shadow-2 border-round p-fluid">
            <div className="p-fluid">
              <ul className="surface-card p-0 mb-4 list-none flex overflow-x-auto select-none">
                <li className="mr-2">
                  <button
                    className={classNames(
                      "btn-no-decoration p-ripple cursor-pointer px-4 py-3 flex align-items-center border-round transition-colors transition-duration-150 border-noround",
                      {
                        "primary-bg-color": modeSourceRefAdvisory,
                        "text-700 light-bg-color": !modeSourceRefAdvisory,
                      },
                    )}
                    onClick={() => onAdvisoryModeChange(true)}
                  >
                    <i className="pi pi-copy mr-2"></i>
                    <span className="font-medium">Source/reference-based</span>
                    <Ripple />
                  </button>
                </li>
                <li className="mr-2">
                  <button
                    className={classNames(
                      "btn-no-decoration p-ripple cursor-pointer px-4 py-3 flex align-items-center border-round transition-colors transition-duration-150 border-noround",
                      {
                        "primary-bg-color": !modeSourceRefAdvisory,
                        "text-700 light-bg-color": modeSourceRefAdvisory,
                      },
                    )}
                    onClick={() => onAdvisoryModeChange(false)}
                  >
                    <i className="pi pi-file mr-2"></i>
                    <span className="font-medium">Source-based</span>
                    <Ripple />
                  </button>
                </li>
              </ul>
              <FileUpload
                ref={uploadRef}
                name="testset_upload"
                maxFileSize={MAX_FILESIZE}
                accept={
                  modeSourceRefAdvisory
                    ? ".xliff, .xlf, .sdlxliff, .sdlxlf, .tmx, .txt, .xlsx"
                    : ".docx, .pptx, .xlsx, .txt, .html, .pdf"
                }
                customUpload
                auto={true}
                emptyTemplate={
                  <p className="m-0 small-text">
                    <i className="pi pi-info-circle"></i> Drag and drop or click
                    to upload a test {modeSourceRefAdvisory ? "set" : "file"}.
                    <br />
                    <strong>Supported formats</strong>:{" "}
                    {modeSourceRefAdvisory
                      ? "xliff, sdlxliff, tmx, txt and xlsx."
                      : "docx, pptx, xlsx, txt, html, pdf."}
                    <br />
                    Provide between {MIN_SEGMENTS} - {MAX_SEGMENTS}{" "}
                    {modeSourceRefAdvisory ? "segments" : "sentences"} (one
                    sentence per line). Max file size: &#8203;
                    {MAX_FILESIZE >= 1000000000
                      ? (MAX_FILESIZE / 1000000000).toFixed(0) + " GB"
                      : MAX_FILESIZE >= 1000000
                      ? (MAX_FILESIZE / 1000000).toFixed(0) + " MB"
                      : (MAX_FILESIZE / 1000).toFixed(0) + " kB"}
                    .
                    <br />
                    For more information, please visit the{" "}
                    <a href="/help/faq">FAQ</a> page.
                  </p>
                }
                chooseLabel={
                  loadingFile ? (
                    <ProgressSpinner className={"translate-loading"} />
                  ) : modeSourceRefAdvisory ? (
                    "Upload test set"
                  ) : (
                    "Upload test file"
                  )
                }
                disabled={loadingFile || uploadedFile}
                itemTemplate={itemTemplate}
                uploadHandler={fileUploader}
                className={"w-full test-set-upload"}
              />
            </div>
          </div>
        )}
        {activeStep === 1 && (
          <div className="surface-card p-4 shadow-2 border-round p-fluid">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12 md:col-6">
                <label
                  htmlFor="source_language"
                  className="font-medium text-900"
                >
                  Source language
                </label>
                {availableSourceLanguages.data &&
                availableSourceLanguages.data.length === 1 ? (
                  <InputText
                    value={
                      modeSourceRefAdvisory
                        ? availableSourceLanguages.data[0].label
                        : availableSourceLanguages.data[0].label + " (Detected)"
                    }
                    disabled
                  />
                ) : (
                  <Dropdown
                    id="source_language"
                    options={availableSourceLanguages.data}
                    value={selectedSourceLanguage}
                    onChange={onSourceLanguageChange}
                    filter
                  />
                )}
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label
                  htmlFor="target_language"
                  className="font-medium text-900"
                >
                  Target language
                </label>
                {availableTargetLanguages.data &&
                availableTargetLanguages.data.length === 1 ? (
                  <InputText
                    value={availableTargetLanguages.data[0].label}
                    disabled
                  />
                ) : (
                  <Dropdown
                    id="target_language"
                    options={availableTargetLanguages.data}
                    value={selectedTargetLanguage}
                    onChange={onTargetLanguageChange}
                    filter
                  />
                )}
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label htmlFor="domain" className="font-medium text-900">
                  Domain
                </label>
                <Dropdown
                  id="domain"
                  options={availableDomains.data}
                  value={selectedDomain}
                  onChange={onDomainChange}
                  // filter
                  showClear
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label htmlFor="provider" className="font-medium text-900">
                  Provider
                </label>
                <Dropdown
                  id="provider"
                  options={availableProviders.data}
                  value={selectedProvider}
                  onChange={onProviderChange}
                  // filter
                  showClear
                />
              </div>

              <div className="surface-border border-top-1 opacity-50 mb-4 col-12"></div>

              <div className="text-900 font-medium text-900 text-xl mb-3">
                Select engines
              </div>
              <div className="text-600 text-sm mb-4 col-12">
                Please select minimum 1, maximum {MAX_ENGINES} engines.
                Currently {selectedEngines.length}{" "}
                {selectedEngines.length === 1 ? "engine is" : "engines are"}{" "}
                selected.
              </div>

              <DataTable
                value={engines.data}
                lazy
                totalRecords={engines.count}
                rows={engines.rows}
                first={engines.offset}
                onPage={onPage}
                // loading={engines.loading} // Not pleasant to look at
                selectionMode="checkbox"
                selection={selectedEngines}
                onSelectionChange={(e) => setSelectedEngines(e.value)}
                isDataSelectable={isEngineSelectable}
                selectionPageOnly={true}
                className="field mb-4 col-12"
                emptyMessage="No engines found with current filters. Please broaden the search by selecting less filters."
                paginator
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} engines"
                rowsPerPageOptions={[5, 10, 50]}
              >
                <Column
                  selectionMode="multiple"
                  headerStyle={{ width: "3em" }}
                ></Column>
                <Column
                  field="source_language"
                  header="Source language"
                  body={(e) => sourceLanguageFlagTemplate(e, "source_language")}
                />
                <Column
                  field="target_language"
                  header="Target language"
                  body={(e) => sourceLanguageFlagTemplate(e, "target_language")}
                />
                <Column field="domain" header="Domain" />
                <Column field="provider" header="Provider" />
              </DataTable>

              <div className="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
              <Button
                label="Launch engine advisory"
                className="p-button-success mt-4"
                disabled={selectedEngines.length === 0}
                onClick={confirmLaunchAdvisory}
              />
            </div>
          </div>
        )}
        {activeStep === 0 && (
          <Button
            label="Next"
            icon="pi pi-arrow-right"
            className="w-auto mt-4 border-400"
            disabled={nextStepDisabled()}
            onClick={(e) => setActiveStep(activeStep + 1)}
          />
        )}
      </div>
      <Toast ref={toast} />
      <ConfirmDialog />
    </div>
  );
};

export default EngineAdvisory;
