import { EngineActionTypes } from "../constants/engine-action-types";

const DefaultState = {
  loading: false,
  data: {},
  errorMsg: "",
  count: 0,
  rows: 0,
  page: 1,
  offset: 0,
  query: {},
  all: false,
};

const FirstEngineReducer = (state = DefaultState, action) => {
  switch (action.type) {
    case EngineActionTypes.FIRST_AVAILABLE_ENGINE_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case EngineActionTypes.FIRST_AVAILABLE_ENGINE_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: "Unable to fetch engine",
        count: 0,
        rows: 0,
      };
    case EngineActionTypes.FIRST_AVAILABLE_ENGINE_SUCCESS:
      let data = action.payload.map((engine) => {
        let label = `${engine.domain}`;
        return {
          value: engine.id,
          label: label,
        };
      })[0];
      return {
        ...state,
        loading: false,
        data: data,
        errorMsg: "",
        count: action.payload.length,
        rows: action.rows,
        offset: action.offset,
        query: action.query,
        all: action.all,
      };
    default:
      return state;
  }
};

export default FirstEngineReducer;
