import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";

const CrossLangTheme = lazy(() => import("./CrossLangTheme"));
const BlueTheme = lazy(() => import("./BlueTheme"));
const GreenTheme = lazy(() => import("./GreenTheme"));
const AgTheme = lazy(() => import("./AgTheme"));
const WcsTheme = lazy(() => import("./WcsTheme"));
const PowerlingTheme = lazy(() => import("./PowerlingTheme"));

export const ThemeSelector = ({ children }) => {
  const auth = useSelector((state) => state.auth);

  const companyThemeMap = {
    crosslang: <CrossLangTheme />,
    // ag: <AgTheme />,
    blue: <BlueTheme />,
    green: <GreenTheme />,
    // WCS group:
    cerasus: <WcsTheme />,
    dct: <WcsTheme />,
    "duo vertaalbureau": <WcsTheme />,
    "l&l": <WcsTheme />,
    "marketing wcs": <WcsTheme />,
    "vertaalbureau bothof": <WcsTheme />,
    wcs: <WcsTheme />,
    "wcs academy": <WcsTheme />,
    "wcs bpm": <WcsTheme />,
    "wilkens gent": <WcsTheme />,
    "wilkens leiden": <WcsTheme />,
    powerling: <PowerlingTheme />,
  };

  const selectedTheme = companyThemeMap[auth?.company?.toLowerCase()] || (
    <CrossLangTheme />
  );

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>{selectedTheme}</Suspense>
      {children}
    </>
  );
};
