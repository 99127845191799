import React, { useEffect } from "react";
import { useInterval } from "../../Helpers";
import { GetTestSetTranslationsFromEngineAdvisory } from "../../actions/advisoryActions";
import api from "../../interceptors/api";
import { useDispatch, useSelector } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import { placeholderValue } from "../../utils/formatting";

const EngineAdvisoryProcessing = (props) => {
  const dispatch = useDispatch();

  const inProgress = props.inProgress;
  const setInProgress = props.setInProgress;

  const currentAdvisoryId = props.engineAdvisoryId;

  const sourceLanguage = props.sourceLanguage;
  const targetLanguage = props.targetLanguage;
  const filename = props.filename;

  // Reducers
  const testSetTranslations = useSelector((state) => state.testSetTranslations);

  // UserManagement / Magic numbers

  /* total time = POLL_INTERVAL_MS * POLL_MAX_ATTEMPTS
   * = 2000 * 500 = 1000000 ms = 1000 s = 16.6 min
   */
  const POLL_INTERVAL_MS = 2000;
  const POLL_MAX_ATTEMPTS = 500;

  /** The first render of UseInterval will only happen after the delay has passed.
   * To immediately send first request when inProgress is true, useEffect is used.
   */
  useEffect(() => {
    if (inProgress) {
      getTestSetTranslations(currentAdvisoryId);
    }
  }, [inProgress, currentAdvisoryId]);
  useInterval(
    () => {
      getTestSetTranslations(currentAdvisoryId);
    },
    inProgress ? POLL_INTERVAL_MS : null,
    POLL_MAX_ATTEMPTS
  );

  const getTestSetTranslations = (currentAdvisoryId) => {
    dispatch(GetTestSetTranslationsFromEngineAdvisory(currentAdvisoryId));
    let request = api.get(
      `/engine_advisory/api/task/status/${currentAdvisoryId}`
    );
    return request.then((result) => {
      // Check if all test set translations and processing are done
      setInProgress(!result.data);
      return result;
    });
  };

  const renderEngineCards = () => {
    // Sort by provider and engine id
    testSetTranslations.data.sort((a, b) => {
      if (a.engine.provider > b.engine.provider) return 1;
      if (a.engine.provider < b.engine.provider) return -1;
      if (a.engine.id > b.engine.id) return 1;
      if (a.engine.id < b.engine.id) return -1;
      return 0;
    });

    return (
      <div className="grid mt-0 mr-0">
        {testSetTranslations.data.map((testSetTranslation, i) => (
          <div className="col-12 md:col-6 align-items-end" key={i}>
            <div
              className={`p-3 border-1 surface-border border-round surface-card justify-content-between h-full align-items-between flex flex-column ${
                testSetTranslation.status === "FAILED" ? "bg-pink-100" : ""
              }`}
            >
              <div className="text-700 flex align-items-stretch justify-content-between">
                <div className="text-900">
                  <i className="pi pi-cog mr-2"></i>
                  <span className="font-medium">
                    {testSetTranslation.engine_name}
                  </span>
                </div>
                <div
                  className={`font-medium ${
                    testSetTranslation.status === "FAILED"
                      ? "text-error"
                      : "primary-color"
                  }`}
                >
                  {testSetTranslation.status === "FAILED" && "FAILED"}
                  {testSetTranslation.status === "PENDING" && (
                    <span className="primary-color font-medium flex flex-row">
                      PENDING&nbsp;
                      <ProgressSpinner
                        style={{ width: "20px", height: "20px" }}
                        animationDuration=".5s"
                      />
                    </span>
                  )}
                  {testSetTranslation.status === "IN_PROGRESS" &&
                    testSetTranslation.progress <= 0 && (
                      <span className="primary-color font-medium flex flex-row">
                        STARTING&nbsp;
                        <ProgressSpinner
                          style={{ width: "20px", height: "20px" }}
                          animationDuration=".5s"
                        />
                      </span>
                    )}
                  {testSetTranslation.status === "IN_PROGRESS" &&
                    testSetTranslation.progress > 0 &&
                    testSetTranslation.progress < 100 &&
                    parseFloat(testSetTranslation.progress).toFixed(1) + "%"}
                  {testSetTranslation.status === "IN_PROGRESS" &&
                    testSetTranslation.progress >= 100 && (
                      <span className="primary-color font-medium flex flex-row">
                        FINALIZING TRANSLATION&nbsp;
                        <ProgressSpinner
                          style={{ width: "20px", height: "20px" }}
                          animationDuration=".5s"
                        />
                      </span>
                    )}
                  {testSetTranslation.status === "SUCCESS" && (
                    <span className="primary-color font-medium">
                      GENERATING RESULTS&nbsp;
                      <ProgressSpinner
                        style={{ width: "20px", height: "20px" }}
                        animationDuration=".5s"
                      />
                    </span>
                  )}
                </div>
              </div>
              <div
                className="surface-300 w-full mt-2"
                style={{ height: "7px", borderRadius: "4px" }}
              >
                <div
                  className={
                    testSetTranslation.status === "FAILED"
                      ? "bg-pink-500 h-full"
                      : "primary-bg-color h-full"
                  }
                  style={
                    testSetTranslation.status === "FAILED"
                      ? { width: `100%`, borderRadius: "4px" }
                      : {
                          width: `${testSetTranslation.progress}%`,
                          borderRadius: "4px",
                        }
                  }
                ></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div className="surface-card p-4 shadow-2 border-round">
        <ul className="list-none p-0 m-0 border-top-1 surface-border">
          <li
            key="selected-src"
            className="flex align-items-center py-3 px-2 flex-wrap surface-ground"
          >
            <div className="text-500 w-full md:w-2 font-medium">
              Source language
            </div>
            <div className="text-900 w-full md:w-10">{sourceLanguage}</div>
          </li>
          <li
            key="selected-trg"
            className="flex align-items-center py-3 px-2 flex-wrap"
          >
            <div className="text-500 w-full md:w-2 font-medium">
              Target language
            </div>
            <div className="text-900 w-full md:w-10 line-height-3">
              {targetLanguage}
            </div>
          </li>
          <li
            key="uploaded-test-set"
            className="flex align-items-center py-3 px-2 flex-wrap surface-ground"
          >
            <div className="text-500 w-full md:w-2 font-medium">Test set</div>
            <div className="flex md:align-items-center md:justify-content-between py-3 px-2 flex-column md:flex-row">
              <div className="flex align-items-center">
                <span className="block pi pi-file mr-2"></span>
                {/* Only show filename, not complete path. */}
                <span className="text-900">{placeholderValue(filename)}</span>
              </div>
            </div>
          </li>
          <li
            key="selected-engines"
            className="flex align-items-center py-3 px-2 flex-wrap"
          >
            <div className="text-500 w-full md:w-2 font-medium">Engines</div>
            <div className="text-900 w-full md:w-10">{renderEngineCards()}</div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default EngineAdvisoryProcessing;
