import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetActiveTerms } from "../../../actions/termsActions";

const TermsList = () => {
  const dispatch = useDispatch();

  const tos = useSelector((state) => state.tos);

  useEffect(() => {
    dispatch(GetActiveTerms());
  }, [dispatch]);

  return <div dangerouslySetInnerHTML={{ __html: tos.data.content }}></div>;
};

export default TermsList;
