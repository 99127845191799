import { ProviderActionTypes } from "../constants/provider-action-types";

const DefaultState = {
  loading: false,
  data: [], // Each element {label: "Crosslang", value: "cl", "disabled" (optional): true}
  errorMsg: "",
};

const ProviderListReducer = (state = DefaultState, action) => {
  switch (action.type) {
    case ProviderActionTypes.PROVIDER_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case ProviderActionTypes.PROVIDER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: "Unable to fetch providers",
      };
    case ProviderActionTypes.PROVIDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: "",
      };
    default:
      return state;
  }
};

export default ProviderListReducer;
