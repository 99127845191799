import { EngineActionTypes } from "../constants/engine-action-types";

const DefaultState = {
  loading: false,
  data: [], // Each element {label: "Generic", value: "generic", "disabled" (optional): true}
  errorMsg: "",
};

const DomainListReducer = (state = DefaultState, action) => {
  switch (action.type) {
    case EngineActionTypes.AVAILABLE_DOMAINS_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case EngineActionTypes.AVAILABLE_DOMAINS_FAIL:
      return {
        ...state,
        loading: false,
        data: [],
        errorMsg: "Unable to fetch domains",
      };
    case EngineActionTypes.AVAILABLE_DOMAINS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: "",
      };
    case EngineActionTypes.AVAILABLE_DOMAINS_RESET:
      return DefaultState;
    default:
      return state;
  }
};

export default DomainListReducer;
