import { combineReducers } from "redux";
import UiReducer from "./uiReducer";
import AuthReducer from "./AuthReducer";
import EngineListReducer from "./EngineListReducer";
import ProviderListReducer from "./ProviderListReducer";
import TmsListReducer from "./TmsListReducer";
import ProviderMultipleReducer from "./ProviderMultipleReducer";
import TmsMultipleReducer from "./TmsMultipleReducer";
import ConfigurationListReducer from "./ConfigurationListReducer";
import ConfigurationMultipleReducer from "./ConfigurationMultipleReducer";
import ConfigurationCreateReducer from "./ConfigurationCreateReducer";
import ConfigurationUpdateReducer from "./ConfigurationUpdateReducer";
import SourceLanguageListReducer from "./SourceLanguageListReducer";
import TargetLanguageListReducer from "./TargetLanguageListReducer";
import DomainListReducer from "./DomainListReducer";
import EngineProviderListReducer from "./EngineProviderListReducer";
import TagListReducer from "./TagListReducer";
import AdvisoryReducer from "./AdvisoryReducer";
import TestSetReducer from "./TestSetReducer";
import TestSetTranslationsReducer from "./TestSetTranslationsReducer";
import FirstEngineReducer from "./FirstEngineReducer";
import TermsReducer from "./TermsReducer";
import TranslatedFileStatusReducer from "./TranslatedFileStatusReducer";
import AnalysisReducer from "./AnalysisReducer";
import InvitedUserListReducer from "./InvitedUserListReducer";
import LastEngineReducer from "./LastEngineReducer";

const RootReducer = combineReducers({
  uiStates: UiReducer,
  auth: AuthReducer,
  invitedUserList: InvitedUserListReducer,

  engineList: EngineListReducer,
  firstAvailableEngine: FirstEngineReducer,
  availableSourceLanguages: SourceLanguageListReducer,
  availableTargetLanguages: TargetLanguageListReducer,
  availableDomains: DomainListReducer,
  availableProviders: EngineProviderListReducer,
  availableTags: TagListReducer,

  // Store the last engine used/selected for translation
  lastEngine: LastEngineReducer,

  configurationList: ConfigurationListReducer,
  configuration: ConfigurationMultipleReducer,
  configurationCreate: ConfigurationCreateReducer,
  configurationUpdate: ConfigurationUpdateReducer,

  providerList: ProviderListReducer,
  provider: ProviderMultipleReducer,

  translatedFileStatus: TranslatedFileStatusReducer,
  analysis: AnalysisReducer,

  advisory: AdvisoryReducer,
  testSet: TestSetReducer,
  testSetTranslations: TestSetTranslationsReducer,

  tmsList: TmsListReducer,
  tms: TmsMultipleReducer,

  tos: TermsReducer,
});

export default RootReducer;
