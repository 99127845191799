import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FaqList = () => {
  return (
    <div className="faq">
      <h1>FAQ</h1>
      <h2>1. CrossLang MT Gateway</h2>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            What is the CrossLang Machine Translation Gateway?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Machine Translation has gone mainstream but finding the “right”
            engine for your project and managing all the different subscriptions
            and integrations is challenging. The CrossLang Machine Translation
            Gateway addresses all these challenges by providing a single gateway
            to access thousands of ready-to-use and custom engines.
          </Typography>
          <Typography>
            In addition, where a quick answer is required, the Gateway provides
            automated recommendations for which engine is best suited to your
            content. Easy to use, from a single integration, the CrossLang
            Machine Translation Gateway delivers a wealth of features for just
            one monthly invoice.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            What are the benefits of the CrossLang Machine Translation Gateway?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The main benefits of the CrossLang Machine Translation Gateway are:
            <ul>
              <li>
                Access to thousands of engines and hundreds of language pairs
                through a single gateway.
              </li>
              <li>Intuitive, web-based interface</li>
              <li>A single consolidated monthly charge</li>
              <li>Secure translation interface and NLP features as options</li>
              <li>
                Hosted securely in the EU in our ISO 27001 compliant data center
              </li>
              <li>Access to CrossLang experts whenever required</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <h2>2. Machine Translation (MT)</h2>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What are Machine Translation domains?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The CrossLang Machine Translation Gateway makes a distinction
            between generic and domain-specific machine translation models.
            Machine translation models are created using billions of translated
            documents called training data. This data can be generic,
            domain-specific or even customer-specific. Major MT providers, such
            as Google, Microsoft or Amazon are mainly intended to be used for
            generic content (they are therefore referred to as general-purpose
            engines). Domain-specific MT engines, on the other hand, are more
            fine-tuned towards a certain domain, usually resulting in more
            accurate MT output.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            Which languages and MT providers are supported in the CrossLang MT
            Gateway?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The CrossLang Machine Translation Gateway allows you to translate in
            any language pair covered by the major MT providers. Currently, over
            600 language pairs are available, and new languages are added on a
            regular basis. Go to Configure → Engines to see which language pairs
            are available and to Configure → Providers for more information
            about the different MT providers.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Which providers are GDPR-compliant? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              Companies that handle personal data of EU citizens, regardless of
              their location, have to comply with the 2018 GDPR Regulations.
              Below you can find an overview of the various suppliers and their
              compliance status with GDPR. For those who comply, a link to their
              GDPR information is provided.{" "}
            </p>
            <p>
              Compliant providers:
              <ul>
                <li>
                  <strong>Deepl</strong>:{" "}
                  <a href="https://www.deepl.com/en/privacy">
                    https://www.deepl.com/en/privacy
                  </a>
                </li>
                <li>
                  <strong>Microsoft</strong>:{" "}
                  <a href="https://www.microsoft.com/en-us/translator/business/notrace/">
                    https://www.microsoft.com/en-us/translator/business/notrace/
                  </a>
                </li>
                <li>
                  <strong>Systran</strong>:{" "}
                  <a href="https://www.systransoft.com/systran/policies/privacy-policy/">
                    https://www.systransoft.com/systran/policies/privacy-policy/
                  </a>
                </li>
                <li>
                  <strong>CrossLang</strong>:{" "}
                  <a href="https://www.crosslang.com/privacy-policy/">
                    https://www.crosslang.com/privacy-policy/
                  </a>
                </li>
                <li>
                  <strong>Amazon</strong>:{" "}
                  <a href="https://aws.amazon.com/compliance/gdpr-center/">
                    https://aws.amazon.com/compliance/gdpr-center/
                  </a>
                </li>
                <li>
                  <strong>Google</strong>:{" "}
                  <a href="https://cloud.google.com/translate/data-usage">
                    https://cloud.google.com/translate/data-usage
                  </a>
                </li>
              </ul>
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <Typography>
            Document analysis: How to interpret readability scores?{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              The Flesch-Kincaid Grade Level is a readability score that ranges
              from 1 to 18. It tells you how difficult it is to read and
              understand a piece of text, with lower scores indicating easier to
              read text. For example, a score of 6 means the text is easy to
              read and can be understood by someone in the sixth grade.
            </p>
            <p>
              A Flesch-Kincaid Grade Level score of 18 indicates that the text
              is very difficult to read and can only be understood by someone
              with a high level of education, such as a person with a graduate
              degree. Texts with a score of 18 are typically written in complex
              language and contain technical jargon that may be difficult for
              the average reader to understand.
            </p>
            <p>
              For instance, Roald Dahl's "Charlie and the Chocolate Factory" has
              a score of 5-6, making it an easy-to-read book suitable for
              children. On the other hand, Stephen Hawking's "A Brief History of
              Time" typically has a score in the 17-18 range, making it a highly
              technical and complex book written for readers with advanced
              education and knowledge in physics and cosmology.
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <h2>3. Translation Management System (TMS)</h2>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            How can I use the CrossLang Machine Translation Gateway from my TMS
            of choice?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The CrossLang Machine Translation Gateway integrates with most TMS
            systems in use today, such as memoQ, Memsource, XTM, SDL Worldserver
            and SDL Trados Studio. For a complete overview of the supported TMS
            systems and instructions for connecting your TMS of choice to the
            CrossLang MT Gateway, please navigate to the Help → TMS tab. Still
            can’t find the TMS you are looking for? Don’t hesitate to contact
            us.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <h2>4. Engine Advisory</h2>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is the Automated Engine Advisory?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In Machine Translation, there is no one-fits-all solution. Selecting
            the best machine translation provider and model in order to deliver
            the highest quality translations for your project depends on various
            factors: the language pair, the domain and terminology used (e.g.
            user-generated content vs. legal documents vs. marketing material),
            the tone of voice (formal vs. informal), … The automated engine
            advisory helps you find the best machine translation engine for your
            use case, by comparing different MT providers and available domains.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>What criteria should the test set meet?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To be able to estimate which engine best suits your project, we need
            you to upload a small sample representing the type of data envisaged
            for translation. This sample should meet the following criteria:
            <ul>
              <li>the sample should be between 200 and 1000 segments</li>
              <li>
                the content of the sample should be as close as possible to the
                content of the envisaged translation
              </li>
              <li>
                we advise not to upload term lists (glossaries), but to use
                average-length sentences
              </li>
            </ul>
          </Typography>
          <Typography>
            In compliance with GDPR regulations, we do not store any data from
            the test set for more than 24h.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What file types are supported?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can use any of the following standard bilingual formats:
            <ul className="list-disc">
              <li>
                <strong>xliff</strong> (Localisation Interchange File Format):
                an XML-based format which is used to pass data between tools
                during a localization process
              </li>
              <li>
                <strong>sdlxliff</strong>: xliff files saved in SDL Trados
                Studio
              </li>
              <li>
                <strong>tmx</strong> (Translation Memory eXchange): this format
                is used to store a Translation Memory (TM) and allows for the
                exchange of TM data between CAT tools
              </li>
            </ul>
          </Typography>
          <Typography>
            Make sure your test set meets the following criteria:
            <ul className="list-disc">
              <li>
                source and target languages have to match the list of supported
                language codes (ISO 639-1)
              </li>{" "}
              <li>
                target segments cannot be empty: both a source and a translation
                have to be included to be used for the advisory
              </li>
            </ul>
          </Typography>
          <Typography>
            In addition, txt and xlsx formats are supported:
            <ul className="list-disc">
              <li>
                <strong>txt</strong> (standard text document containing plain
                text)/<strong>tsv</strong> (tab-separated values)
                <ul className="list-disc">
                  <li>
                    the first line of the txt file should contain the language
                    codes for the source and target languages, separated by a
                    tab character.
                  </li>
                  <li>
                    subsequent lines in the txt file should consist of a single
                    pair of source and target segments, separated by a tab
                    character.
                  </li>
                </ul>
              </li>
              <li>
                <strong>xlsx</strong> (Microsoft Excel)
                <ul className="list-disc">
                  <li>
                    the first row of the xlsx file should contain the language
                    codes for the source and target languages, in the first and
                    second columns, respectively.
                  </li>
                  <li>
                    subsequent rows should contain a single pair of source and
                    target segments.
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            Where can I find my Automated Engine Advisory results?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In order to see an overview of your advisories, navigate to Advisory
            → History. This page shows all advisories and includes:
            <ul>
              <li>the date the advisory was run</li>{" "}
              <li>the source and target language</li>{" "}
              <li>the name of the test set</li> <li>the best engine</li>
            </ul>
          </Typography>
          <Typography>
            By clicking on a past advisory, more details appear, such as the
            automatic scores per provider.
          </Typography>
          <Typography>
            The results of an advisory are also sent to you via email as soon as
            the advisory has been completed.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            How do I interpret the Automated Engine Advisory scores ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In the advanced view of the engine advisory, the user is presented
            with various scores allowing to compare the quality of different
            machine translation systems. The following industry-standard quality
            metrics are shown:
            <ul>
              <li>
                <strong>BLEU</strong> (short for “Bilingual Evaluation
                Understudy”) compares a machine-generated translation to a
                reference translation, typically created by a human translator.
                The rule of thumb is: the higher the BLEU score, the closer the
                output to the reference translation. Generally speaking, a BLEU
                score above 30 reflects an understandable translation. A score
                over 50 means this is a good, fluent translation. The maximum
                BLEU score lies around 80, meaning that the output produced is
                very close to the reference translation provided. Although the
                scores are displayed as a percentage, it is important to note
                that the BLEU score is not the percentage of correctly
                translated text. For example, it would be wrong to assume that a
                translation with a BLEU score of 50 means that only 50% of
                sentences were translated correctly.
              </li>
              <li>
                The <strong>edit distance</strong> measures the number of edits
                (insertions, deletions, substitutions) that need to be made to
                go from the machine translation output back to the reference
                translation. In other words, it estimates the post-editing
                effort that would be required. The lower this score, the less
                edits are needed and the better the engine. The edit distance
                counts these edits for each sentence on a character level.
                Afterwards, an average is generated which takes the sentence
                lengths into account to obtain a TER score for the entire test
                set.
              </li>
              <li>
                <strong>TER</strong> stands for Translation Error Rate and is
                calculated in a similar way to edit distance. One of the
                differences is that TER takes edits on a word level into
                account, not on a character level. Like with the edit distance,
                the lower the translation error rate, the better.
              </li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FaqList;
