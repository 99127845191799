import { useLocation, useParams } from "react-router-dom";
import api from "../../interceptors/api";
import React, { useEffect, useState } from "react";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Badge } from "primereact/badge";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { DeferredContent } from "primereact/deferredcontent";
import { getDateTime, placeholderValue } from "../../utils/formatting";
import EngineAdvisoryProcessing from "./EngineAdvisoryProcessing";
import ProgressBarAdvisory from "./ProgressBarAdvisory";
import AddToConfiguration from "./AddToConfiguration";
import { GetConfigurationsListAll } from "../../actions/configurationActions";
import { useDispatch } from "react-redux";

const InfoIcon = (props) => (
  <i className={"pi pi-info-circle text-sm " + props.className} />
);

const EADetails = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  const { id } = useParams();

  const [advisoryId, setAdvisoryId] = useState(id);

  const [resultsScores, setResultsScores] = useState([]);
  const [resultsDiffs, setResultsDiffs] = useState([]);

  const [sourceLanguage, setSourceLanguage] = useState();
  const [targetLanguage, setTargetLanguage] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [filename, setFilename] = useState();
  const [sourceBased, setSourceBased] = useState(false);

  /** Steps
   * 0 - Initial
   * 1 - /
   * 2 - Processing
   * 3 - Results
   */
  const [step, setStep] = useState(state?.step || 0);
  const [processing, setProcessing] = useState(null);

  // Simple vs Advanced view
  const [advancedView, setAdvancedView] = useState(false);

  // Initialising the state variables from advisoryId
  // Load the available configurations one time, the same list will be used for each AddToConfiguration component
  useEffect(() => {
    setAdvisoryId(id);
    dispatch(GetConfigurationsListAll());
  }, [id, dispatch]);

  // After settings the advisory id, we can fetch all the results.
  useEffect(() => {
    const fetchEADetails = async () => {
      let eADetails = await api.get(
        `engine_advisory/api/engine_advisory/${advisoryId}`,
      );

      let eaData = eADetails.data;

      setSourceLanguage(eaData.source_language_name);
      setTargetLanguage(eaData.target_language_name);
      setSourceBased(eaData.source_based);
      setCreatedAt(new Date(eaData.created_at));
      setFilename(eaData.filename);

      setStep(["SUCCESS", "FAILED"].includes(eaData.status) ? 3 : 2);
    };

    const fetchResultsScores = async () => {
      if (advisoryId !== null) {
        let res = await api.get(
          `/engine_advisory/api/results/${advisoryId}/scores`,
        );

        setResultsScores(res.data);
      }
    };

    async function fetchResultsDiffs() {
      if (advisoryId !== null) {
        let res = await api.get(`/engine_advisory/api/results/${advisoryId}`);

        setResultsDiffs(res.data.diffs);
      }
    }

    fetchEADetails().then(); // Details not used at the moment

    if (step === 3) {
      fetchResultsScores().then();
      fetchResultsDiffs().then();
    }
  }, [advisoryId, step]);

  const highlighBestScores = () => {
    if (!sourceBased) {
      highlighBestBleuScores();
      highlighBestEditDistanceScores();
      highlighBestTerScores();
    } else {
      highlightBestCometScores();
    }
  };

  const highlighBestBleuScores = () => {
    let bleuScoreElements = document.querySelectorAll("td.p-dt-tooltip-bleu");
    let bleuScores = [];
    for (let i = 0; i < bleuScoreElements.length; i++) {
      bleuScores.push(+bleuScoreElements[i].innerText.match(/\d|\./g).join(""));
      if (i === bleuScoreElements.length - 1) {
        let highestBleuScore = Math.max.apply(Math, bleuScores);
        for (const bleuElement of bleuScoreElements) {
          if (bleuElement.textContent.includes(highestBleuScore)) {
            bleuElement.classList.add("best-score");
          }
        }
      }
    }
  };

  const highlighBestEditDistanceScores = () => {
    let editDistanceScoreElements =
      document.querySelectorAll("td.p-dt-tooltip-ed");
    let editDistanceScores = [];
    for (let i = 0; i < editDistanceScoreElements.length; i++) {
      editDistanceScores.push(
        +editDistanceScoreElements[i].innerText.match(/\d|\./g).join(""),
      );
      if (i === editDistanceScoreElements.length - 1) {
        let lowestEditDistanceScore = Math.min.apply(Math, editDistanceScores);
        for (const editDistanceElement of editDistanceScoreElements) {
          if (
            editDistanceElement.textContent.includes(lowestEditDistanceScore)
          ) {
            editDistanceElement.classList.add("best-score");
          }
        }
      }
    }
  };

  const highlighBestTerScores = () => {
    let terScoreElements = document.querySelectorAll("td.p-dt-tooltip-ter");
    let terScores = [];
    for (let i = 0; i < terScoreElements.length; i++) {
      terScores.push(+terScoreElements[i].innerText.match(/\d|\./g).join(""));
      if (i === terScoreElements.length - 1) {
        let lowestTerScore = Math.min.apply(Math, terScores);
        for (const terScoreElement of terScoreElements) {
          if (terScoreElement.textContent.includes(lowestTerScore)) {
            terScoreElement.classList.add("best-score");
          }
        }
      }
    }
  };

  const highlightBestCometScores = () => {
    let cometScoreElements = document.querySelectorAll("td.p-dt-tooltip-comet");
    let cometScores = [];
    for (let i = 0; i < cometScoreElements.length; i++) {
      cometScores.push(
        +cometScoreElements[i].innerText.match(/\d|\./g).join(""),
      );
      if (i === cometScoreElements.length - 1) {
        let highestCometScore = Math.max.apply(Math, cometScores);
        for (const cometScoreElement of cometScoreElements) {
          if (cometScoreElement.textContent.includes(highestCometScore)) {
            cometScoreElement.classList.add("best-score");
          }
        }
      }
    }
  };

  // Custom header including info, tooltip and switch button for advanced view
  const headerScores = (
    <div className="font-normal table-header justify-content-between align-items-center flex">
      {/*<h3 className="m-0">Engines</h3>*/}
      <div className="m-0">
        <p className="m-0">
          {sourceLanguage} > {targetLanguage} Engine Advisory report
        </p>
        <p className="m-0">Test set: {placeholderValue(filename)}</p>
        <p className="m-0">Created at: {createdAt && getDateTime(createdAt)}</p>
      </div>
      <div className="flex align-items-center">
        <label className={"mr-2"}>Advanced view</label>

        <InputSwitch
          checked={advancedView}
          onChange={(e) => {
            setAdvancedView(e.value);
            highlighBestScores();
          }}
          name={"advancedView switch"}
          id="switchAdvancedView"
        />
      </div>
    </div>
  );

  // Custom header for accordion view. Could be extracted to a separate component.
  const headerTemplate = (options) => {
    const toggleIcon = options.collapsed
      ? "pi pi-chevron-down"
      : "pi pi-chevron-up";

    return (
      <div className={"p-panel-header "}>
        <Button
          className={
            "p-button-plain p-button-text flex justify-content-between no-focus transparent-no-hover"
          }
          onClick={options.onTogglerClick}
          style={{
            alignSelf: "flex",
            padding: "0px",
            margin: "0px",
            width: "100%",
          }}
        >
          <div>
            <h2 className="m-0"> {options.props.header} </h2>
          </div>
          <div>
            <span
              className={`m-0 ${toggleIcon}`}
              style={{ fontSize: "1.2em", fontWeight: "bold" }}
            ></span>
          </div>
          <Ripple />
        </Button>
      </div>
    );
  };

  const infoText = (header, className) => (
    <>
      {header} <InfoIcon className={className} />
    </>
  );

  // Table containing the sentence level differences and scores
  const diffTable = () => {
    return (
      <DeferredContent>
        <DataTable value={resultsDiffs} stripedRows>
          <Column field="type" header="Type" />
          <Column
            field="sentence"
            header="Sentence"
            body={sentenceBodyTemplate}
          />
          {!sourceBased && (
            <Column
              field="BLEU"
              header="BLEU (%)"
              body={(rowData, row) =>
                percentageTemplate(rowData, row, 2, false)
              }
            />
          )}
          {!sourceBased && <Column field="DIST" header="DIST" />}
          {sourceBased && (
            <Column
              field="COMET"
              header="COMET (%)"
              body={(rowData, row) =>
                percentageTemplate(rowData, row, 2, false)
              }
            />
          )}
        </DataTable>
      </DeferredContent>
    );
  };

  // Add row data to configuration
  const addRowDataToConfig = (rowData, row) => {
    return <AddToConfiguration rowData={rowData} />;
  };

  const renderResults = () => {
    return (
      <div>
        <h1>Document evaluation</h1>
        <div>
          <p>
            According to the automated engine advisory, the marked engines are
            best suited for the translation of your content.
          </p>
        </div>
        {advancedView && (
          <>
            <Tooltip
              target=".p-dt-tooltip-bleu"
              content="Higher is better. BLEU is a standard measure for comparing machine translation quality. The higher the score, the closer a translation is to its human reference."
              mouseTrack
              mouseTrackLeft={10}
            />
            <Tooltip
              target=".p-dt-tooltip-ed"
              content="Lower is better. Edit Distance shows the normalized Levenshtein distance: the number of edits required to get to the reference translation normalized over the sentence length. The lower the score, the closer a translation is to its human reference."
              mouseTrack
              mouseTrackLeft={10}
            />
            <Tooltip
              target=".p-dt-tooltip-ter"
              content="Lower is better. TER is an edit distance-based metric that also takes into account shifts. The lower the score, the better the translation."
              mouseTrack
              mouseTrackLeft={10}
            />
            <Tooltip
              target=".p-dt-tooltip-comet"
              content="Higher is better. COMET is a metric that measures the quality of a translation, without requiring a reference translation. The higher the score, the better the translation."
              mouseTrack
              mouseTrackLeft={10}
            />
          </>
        )}
        <DataTable
          value={resultsScores}
          style={{ backgroundColor: "rgba(0, 0, 0, 0.15) !important" }}
          header={headerScores}
          resizableColumns
          columnResizeMode="fit"
        >
          <Column
            field="engine.name"
            header="Engine"
            body={engineBodyTemplate}
            // resizeable={false}
            style={{ width: "30%" }}
          />
          {!advancedView && (
            <Column
              body={rankingTemplate}
              header="Ranking"
              alignHeader={"center"}
              align={"center"}
            />
          )}
          {advancedView && !sourceBased && (
            <Column
              field="BLEU"
              header={infoText("BLEU (%)", "p-dt-tooltip-bleu")}
              body={(rowData, row) =>
                percentageTemplate(rowData, row, 2, false)
              }
            />
          )}
          {advancedView && !sourceBased && (
            <Column
              field="DIST"
              header={infoText("Edit Distance (%)", "p-dt-tooltip-ed")}
              body={(rowData, row) =>
                percentageTemplate(rowData, row, 2, false)
              }
            />
          )}
          {advancedView && !sourceBased && (
            <Column
              field="TER"
              styleClass={"noBorder"}
              header={infoText("TER (%)", "p-dt-tooltip-ter")}
              body={(rowData, row) =>
                percentageTemplate(rowData, row, 2, false)
              }
            />
          )}
          {advancedView && sourceBased && (
            <Column
              field="COMET"
              styleClass={"noBorder"}
              header={infoText("COMET (%)", "p-dt-tooltip-comet")}
              body={(rowData, row) =>
                percentageTemplate(rowData, row, 2, false)
              }
            />
          )}
          <Column
            header="Add to configuration"
            alignHeader="right"
            align="right"
            style={{ width: "5%" }}
            body={addRowDataToConfig}
          />
        </DataTable>
        <br />
        <Panel
          header="Details"
          toggleable
          headerTemplate={headerTemplate}
          collapsed={!advancedView}
          onToggle={(e) => {
            setAdvancedView(e.value);
            highlighBestScores();
          }}
          onClick={(e) => {
            setAdvancedView(!advancedView);
            highlighBestScores();
          }}
        >
          <h4>Sentence evaluation</h4>

          {resultsDiffs && resultsDiffs.length > 0
            ? diffTable()
            : "To ensure GDPR compliance, your test set has been deleted. You can find the line-by-line evaluation in the email we sent you."}
        </Panel>
      </div>
    );
  };

  const engineBodyTemplate = (rowData, row) => {
    return <span>{rowData.engine.name}</span>;
  };

  const rankingTemplate = (rowData, row) => {
    if (row.rowIndex >= 3) return;

    let value = ["Best choice", "2nd best choice", "3rd best choice"][
      row.rowIndex
    ];
    let severity = ["success", "info", "danger"][row.rowIndex];

    return (
      <div>
        <Badge
          value={value}
          severity={severity}
          className="margin-auto padding-auto"
        />
      </div>
    );
  };

  /**
   * Displays the values in percentage format with '(%)' in the header
   * Input .5 -> Output 50
   * Input 1 -> Output 100
   */
  const percentageTemplate = (
    rowData,
    row,
    fractionDigits = 2,
    percSign = true,
  ) => {
    let value = rowData[row.field];

    if (isNaN(value)) {
      return "-";
    }

    return (value * 100).toFixed(fractionDigits) + (percSign ? "%" : "");
  };

  const sentenceBodyTemplate = (rowData, index) => {
    return <div dangerouslySetInnerHTML={{ __html: rowData.sentence }}></div>;
  };

  /** Start processing when step is 2 */
  useEffect(() => {
    if (step === 2) {
      setProcessing(true);
    }
  }, [step]);

  /**
   * When processing is done, show the results by setting the step to 3
   */
  useEffect(() => {
    if (processing === false) {
      setStep(3);
      setProcessing(null); // reset processing
    }
  }, [processing]);

  return (
    <div>
      {step === 2 && <ProgressBarAdvisory activeStep={step} />}
      {step === 2 && (
        <EngineAdvisoryProcessing
          engineAdvisoryId={advisoryId}
          inProgress={processing}
          setInProgress={setProcessing}
          sourceLanguage={sourceLanguage}
          targetLanguage={targetLanguage}
          filename={filename}
        />
      )}
      {step === 3 && renderResults()}
    </div>
  );
};

export default EADetails;
