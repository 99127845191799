import { useFormik } from "formik";
import fileDownload from "js-file-download";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { GetInvitedUsers } from "../../actions/authActions";
import { useInterval } from "../../Helpers";
import api from "../../interceptors/api";
import { getDateTime } from "../../utils/formatting";

const customSelectColors = {
  primary: "var(--primary-color)",
  primary25: "var(--accent-color)",
  primary50: "var(--primary-color-lightest)",
  primary75: "var(--primary-color-lightest)",
};

const RenderUsage = () => {
  const [submitting, setSubmitting] = useState(false);
  const [usageId, setUsageId] = useState(null);
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const resetLocalState = () => {
    setUsageId(null);
    setBeginDate(null);
    setEndDate(null);
    setSubmitting(false);
  };

  /* YYYY-MM-DD format in local time (ingore timezone)*/
  const getDateStr = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const validationSchema = Yup.object().shape({
    monthYear: Yup.date().required("Date is required").nullable(),
  });

  const formik = useFormik({
    initialValues: {
      monthYear: new Date(),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      postUsage(values.monthYear);
    },
  });

  const handleDateChange = (e) => {
    formik.handleChange("monthYear")(e);
  };

  const postUsage = async (monthYear) => {
    setSubmitting(true);
    try {
      // Calculate beginDate and endDate based on selected month and year
      // Start on the first day of the month
      // End on the last day of the month
      const beginDate = new Date(
        monthYear.getFullYear(),
        monthYear.getMonth(),
        1,
        0,
        0,
        0,
      );
      const endDate = new Date(
        monthYear.getFullYear(),
        monthYear.getMonth() + 1,
        0,
        23,
        59,
        59,
      );

      setBeginDate(beginDate);
      setEndDate(endDate);

      let formData = new FormData();
      formData.append("begin_date", getDateStr(beginDate));
      formData.append("end_date", getDateStr(endDate));

      const res = await api.post(`/billing/api/usage`, formData);
      setUsageId(res.data.id);
    } catch (e) {
      console.error("Unable to create usage data");
      resetLocalState();
    }
  };

  const getUsage = async () => {
    try {
      const res = await api.get(`/billing/api/usage/${usageId}`);

      if (res.status !== 200) {
        // Not ready yet, try again later
        return;
      }

      let blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });

      const downloadFileName = `usage_${getDateStr(beginDate)}_${getDateStr(
        endDate,
      )}.csv`;
      fileDownload(blob, downloadFileName);
    } catch (e) {
      console.log("Failed to retrieve usage data");
    }
    resetLocalState();
  };

  useInterval(
    () => {
      getUsage();
    },
    usageId ? 1000 : null,
    100,
  );

  return (
    <div className="p-grid p-fluid">
      <div className="p-col-12 p-md-4">
        <p>Download the usage report for the given month.</p>
        <form className="p-inputgroup" onSubmit={formik.handleSubmit}>
          <Button
            className={"w-10rem"}
            label="Download"
            icon={submitting ? "pi pi-spin pi-spinner" : "pi pi-download"}
            type="submit"
            disabled={submitting || !formik.isValid}
          />

          <Calendar
            value={formik.values.monthYear}
            onChange={handleDateChange}
            view="month"
            dateFormat="mm/yy"
            showButtonBar
          />
        </form>
        {formik.errors.monthYear && (
          <div className="col">{formik.errors.monthYear}</div>
        )}
      </div>
    </div>
  );
};

const UserManagement = () => {
  const toast = useRef(null);
  const toast_life = 10000; // miliseconds
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const rowsPerPageOptions = [5, 10, 50];
  const rows = rowsPerPageOptions[0];

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.email) {
        errors.email = "Email is required.";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = "Invalid email address. E.g. example@email.com";
      }
      return errors;
    },
    onSubmit: (data) => {
      inviteUser(data.email);
    },
  });

  const refreshTable = () => {
    dispatch(GetInvitedUsers());
  };

  const inviteUser = async (inviteeEmail) => {
    try {
      const res = await api.post(`/auth/invite`, {
        invitee_email: inviteeEmail,
        inviter_email: auth.user,
      });

      if (res.status === 201) {
        toast.current.show({
          severity: "success",
          summary: "User invited",
          detail:
            "The user has been invited successfully and should receive an email shortly.",
          life: toast_life,
        });
        refreshTable();
      } else if (res.status === 200) {
        toast.current.show({
          severity: "info",
          summary: "Not invited",
          detail: "User already exists.",
          life: toast_life,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Failed",
          detail:
            "Failed to send the invitation. Please try again later or contact an administrator.",
          life: toast_life,
        });
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.current.show({
          severity: "warn",
          summary: "No permission",
          detail: "You do not have the permission to invite users.",
          life: toast_life,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Failed",
          detail:
            "Failed to send the invitation. Please try again later or contact an administrator.",
          life: toast_life,
        });
      }
    }
  };

  const invitedUsers = useSelector((state) => state.invitedUserList);
  const [filters, setFilters] = useState({
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  useEffect(() => {
    dispatch(GetInvitedUsers());
  }, [dispatch]);

  const renderInvitedDate = (user) => {
    return <span>{getDateTime(new Date(user.invitation_date))}</span>;
  };

  const renderStatus = (user) => {
    const statusColor =
      user.status === "ACTIVE"
        ? "success"
        : user.status === "PENDING"
        ? "warning"
        : "danger";

    return (
      <Tag className={`p-tag-${statusColor} w-5rem`} value={user.status} />
    );
  };

  const paginatorTemplate =
    "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown";

  const refreshButton = (
    <Button
      type="button"
      icon="pi pi-refresh"
      onClick={refreshTable}
      className="p-button-outlined p-button-rounded invited-refresh-button"
    />
  );

  const handleCancelInvitation = async (user) => {
    try {
      const res = await api.delete(`/auth/cancel-invite/${user.id}`);
      if (res.status === 204) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "The invitation has been cancelled.",
          life: toast_life,
        });
        refreshTable();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Failed",
          detail: "Failed to cancel the invitation.",
          life: toast_life,
        });
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.current.show({
          severity: "warn",
          summary: "No permission",
          detail: "You do not have the permission to cancel invitations.",
          life: toast_life,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Failed",
          detail: "Failed to cancel the invitation.",
          life: toast_life,
        });
      }
    }
  };

  const deactivateUser = async (user) => {
    try {
      const res = await api.delete(`/auth/deactivate-user/${user.id}`);
      if (res.status === 204) {
        toast.current.show({
          severity: "success",
          summary: "User deactivated",
          detail:
            "The user has been deactivated and will not be able to log in anymore.",
          life: toast_life,
        });
        refreshTable();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Failed",
          detail: "Failed to deactivate the user.",
          life: toast_life,
        });
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.current.show({
          severity: "warn",
          summary: "No permission",
          detail: error.response.data.detail,
          life: toast_life,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Failed",
          detail: "Failed to deactivate the user.",
          life: toast_life,
        });
      }
    }
  };

  const activateUser = async (user) => {
    try {
      const res = await api.post(`/auth/activate-user/${user.id}`);
      if (res.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "User activated",
          detail: "The user has been activated and can log in now.",
          life: toast_life,
        });
        refreshTable();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Failed",
          detail: "Failed to activate the user.",
          life: toast_life,
        });
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.current.show({
          severity: "warn",
          summary: "No permission",
          detail: error.response.data.detail,
          life: toast_life,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Failed",
          detail: "Failed to activate the user.",
          life: toast_life,
        });
      }
    }
  };

  const renderActions = (user) => {
    const sharedClassName = "p-button-sm w-8rem";

    switch (user.status) {
      case "PENDING":
        return (
          <Button
            label="Cancel"
            icon="pi pi-times"
            className={`p-button-danger ${sharedClassName}`}
            onClick={() => handleCancelInvitation(user)}
          />
        );
      case "CANCELLED":
        return (
          <Button
            label="Resend"
            icon="pi pi-refresh"
            className={`p-button-warning ${sharedClassName}`}
            onClick={() => inviteUser(user.email)}
          />
        );
      case "ACTIVE":
        // Don't show the deactivate button for the current user
        if (user.email === auth.user) {
          return null;
        }
        return (
          <Button
            label="Deactivate"
            icon="pi pi-power-off"
            className={`p-button-danger ${sharedClassName}`}
            onClick={() => deactivateUser(user)}
            disabled={user.email === auth.user}
          />
        );
      case "INACTIVE":
        return (
          <Button
            label="Activate"
            icon="pi pi-power-off"
            className={`p-button-success ${sharedClassName}`}
            onClick={() => activateUser(user)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <h2>User management</h2>

      <h3>Translation usage</h3>

      <RenderUsage />

      <h3>New user</h3>
      <div className="p-grid p-fluid">
        <div className="p-col-12 p-md-4">
          <form onSubmit={formik.handleSubmit} className="p-inputgroup">
            <Button
              className={"w-10rem"}
              type="submit"
              icon="pi pi-send"
              label="Send invite"
              disabled={!!formik.errors.email}
            />
            <InputText
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              type="email"
              autoComplete={"off"}
              className={formik.errors.email ? "p-invalid" : ""}
              placeholder="Invitee email"
            />
          </form>
          <div className={"col"}>
            {formik.errors.email ? <div>{formik.errors.email} </div> : null}
          </div>
        </div>
      </div>
      <h3>Invited users</h3>
      <div>
        <DataTable
          value={invitedUsers.data}
          rows={rows}
          totalRecords={invitedUsers.data.length}
          paginator
          paginatorTemplate={paginatorTemplate}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
          rowsPerPageOptions={rowsPerPageOptions}
          paginatorLeft={<div />}
          paginatorRight={refreshButton}
          loading={invitedUsers.loading}
          filters={filters}
          filterDisplay="row"
          onFilter={(e) => setFilters(e.filters)}
          rowHover
        >
          <Column
            field="email"
            header="Email"
            filter
            filterField="email"
            filterPlaceholder="Search"
          />
          <Column
            header="Status"
            style={{ width: "15%" }}
            body={renderStatus}
          />
          <Column
            header="Invited at"
            style={{ width: "25%" }}
            body={renderInvitedDate}
          />
          <Column
            header="Actions"
            style={{ width: "15%" }}
            body={renderActions}
            Wi
          />
        </DataTable>
      </div>

      <Toast ref={toast} />
    </div>
  );
};

export default UserManagement;
