export const AdvisoryActionTypes = {
  ADVISORY_LOADING: "ADVISORY/LOADING",
  ADVISORY_SUCCESS: "ADVISORY/SUCCESS",
  ADVISORY_FAIL: "ADVISORY/FAIL",

  TEST_SET_LOADING: "TEST_SET/LOADING",
  TEST_SET_SUCCESS: "TEST_SET/SUCCESS",
  TEST_SET_FAIL: "TEST_SET/FAIL",

  TEST_SET_TRANSLATIONS_LOADING: "TEST_SET_TRANSLATIONS/LOADING",
  TEST_SET_TRANSLATIONS_SUCCESS: "TEST_SET_TRANSLATIONS/SUCCESS",
  TEST_SET_TRANSLATIONS_FAIL: "TEST_SET_TRANSLATIONS/FAIL",
  TEST_SET_TRANSLATIONS_RESET: "TEST_SET_TRANSLATIONS/RESET",
};
