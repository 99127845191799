import * as React from "react";

const PrivacyList = () => {
  return (
    <div>
      <h1>CrossLang System Privacy Policy</h1>
      <h2>1. Introduction</h2>
      <p>
        This CrossLang Privacy Policy forms part of CrossLang’s System Terms of
        Use (“TOU”). Defined terms in this Privacy Policy have the same meanings
        as in the TOU.{" "}
      </p>
      <p>Privacy is important to CrossLang and its Users. </p>
      <p>
        Please take a moment to read CrossLang’s Privacy Policy to see how
        CrossLang protects your privacy.{" "}
      </p>
      <p>
        Your access to and use of the System is conditional upon your acceptance
        of and compliance with the TOU. By accessing the System, you are deemed
        to agree to, and you agree to comply with, the TOU. Registered Users and
        Registered Organisations are required to accept the TOU expressly as
        part of their User registration process. This Privacy Policy forms part
        of the TOU.{" "}
      </p>
      <p>
        CrossLang may amend the TOU at any time in its sole, absolute
        discretion. Please review the TOU now and refer to the most recent
        version periodically here on the System. When CrossLang posts an amended
        TOU in that location, you are deemed to have been notified of it.{" "}
      </p>
      <p>This Privacy Policy addresses with the following topics: </p>
      <p>(a) Coverage of this Privacy Policy </p>
      <p>(b) Receipt of Information from You </p>
      <p>(c) Use of Your Information </p>
      <p>(d) Protection and Disclosure of Information </p>
      <p>(e) Protection of Others’ Information </p>
      <h2>2. Coverage of this Privacy Policy </h2>
      <p>
        This Privacy Policy covers how CrossLang handles the information it
        receives from you, including information on your surfing behaviour and
        certain Personal Information you provide to CrossLang if you register on
        the Private portion of the System.{" "}
      </p>
      <p>
        At this time Users are free to browse the Public portion of the System
        without registering. However, in order to access and use the Private
        portion of the System, including the CrossLang Machine Translation
        Gateway and its components, you must be a Registered User. CrossLang
        reserves the right to require all Users to register on notice at any
        time.{" "}
      </p>
      <p>
        In order to comply with applicable law, CrossLang is required to collect
        certain Personal Information from you when you register as a User.{" "}
      </p>
      <h2>3. Receipt of Information from You </h2>
      <p>
        CrossLang is required to collect certain Personal Information from you
        when you register on the System. This information includes your full
        legal name, the full legal name and legally registered address of your
        Registered Organisation, your email address, your phone number and your
        IP address.{" "}
      </p>
      <p>
        When you are on the System, CrossLang also receives information from
        your computer and web browser, including your IP address, cookie
        information, and your computer’s software and hardware attributes.
        CrossLang records your IP address every time you log on to the System
        and keeps a log of this information for a period of time.{" "}
      </p>
      <p>
        A cookie is information that your web browser accepts from the websites
        you browse and stores on your computer’s hard drive. It often contains
        information known as an “anonymous unique identifier”. After your
        browser accepts a cookie, the website’s computers are able to identify
        your computer as you browse that website. This allows for continuity of
        service.{" "}
      </p>
      <p>
        CrossLang may place a cookie and similar information on your computer as
        you browse to assist in identifying you on the System during your
        browsing session and also to assist in providing information and
        services to you. This is a normal function of your computer and web
        browser, and the use of cookies and other devices is a technical and
        industry standard process for such purposes. You may configure your
        browser so that it does not accept cookies. However, if you do so, you
        may find it very difficult to browse the web and our System and to
        interact with others online.{" "}
      </p>
      <h2>4. Use of Your Information </h2>
      <p>
        CrossLang uses cookies in an industry standard way in order to provide
        information and services to you and generally to make your experience on
        the System more convenient, efficient and enjoyable.{" "}
      </p>
      <p>CrossLang uses the information it collects from you in order to: </p>
      <p>
        (a) customise the Content and services you see and receive on the System
        and any services you may receive if you are a Registered Organisation
        and you access the Private portion of the System,{" "}
      </p>
      <p>
        (b) fulfil your requests for information, services and products, and
        improve services on the System and elsewhere,{" "}
      </p>
      <p>(c) contact you, and </p>
      <p>(d) conduct research and analysis. </p>
      <h2>5. Protection and Disclosure of Information </h2>
      <p>
        CrossLang does not sell, rent, share or otherwise provide your Personal
        Information to or with third parties, except in the following
        circumstances:{" "}
      </p>
      <p>
        (a) to CrossLang’s and its affiliates’ employees who need the
        information in order to do their jobs and to provide the information or
        services you have requested;{" "}
      </p>
      <p>
        (b) to trusted agents or partners of CrossLang who need the information
        in order to provide the information or services you have requested (such
        trusted agents and partners do not have the right to sell, rent or share
        your information with others);{" "}
      </p>
      <p>
        (c) in accordance with applicable law or legal process, including in
        response to police and law enforcement requests, court orders and
        similar legal processes;
      </p>
      <p>
        (d) when CrossLang in its sole discretion believes it is necessary to
        provide information to a third party in order to investigate, prevent or
        take action in respect of actual or suspected activities or
        circumstances that are illegal that could cause harm or that violate the
        TOU; and{" "}
      </p>
      <p>
        (e) to your Registered Organisation or Registered Organisation
        Administrator upon their request.{" "}
      </p>
      <p>
        Please note that your Registered Organisation and your Registered
        Organisation Administrator may have access to the information you
        provide to CrossLang on the System.{" "}
      </p>
      <p>
        CrossLang may sell, rent, share or otherwise provide non-personally
        identifiable statistics about its Users and their behaviour. Such
        information may include the number of downloads, types of services used,
        how many Users browse the System or log on daily, the pages visited, and
        the IP address of their computer (which may change from session to
        session and may be used to identify the User’s geographic location).
        This information is most often collected in aggregate form, and
        CrossLang does not track this information in relation to Users
        individually, although IP addresses and User ID may be recorded in
        relation to purchases and downloads as part of our standard review and
        fraud prevention efforts.{" "}
      </p>
      <p>
        In addition, CrossLang is required by applicable law to keep logs of
        User activity on the System for a period of time. CrossLang will keep
        such logs in accordance with applicable law but does not use such logs
        for any other purpose, except in respect of User activity on the Private
        System where it is necessary to retain the logs in order to be able to
        validate technical processes and commercial transactions.{" "}
      </p>
      <h2>6. Protection of Others’ Information </h2>
      <p>
        If you possess any confidential information or trade secrets of a third
        party, then you must not disclose such information on or via the System
        unless you have the express authorisation of such third party owner.
        Such disclosure without express authorisation is strictly prohibited by
        the TOU.
      </p>
    </div>
  );
};

export default PrivacyList;
