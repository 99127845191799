import { TermsActionTypes } from "../constants/terms-action-types";
import api from "../interceptors/api";

export const GetActiveTerms = () => async (dispatch) => {
  try {
    dispatch({
      type: TermsActionTypes.ACTIVE_TERMS_LOADING,
    });

    const res = await api.get(`/terms/api/active`);

    dispatch({
      type: TermsActionTypes.ACTIVE_TERMS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TermsActionTypes.ACTIVE_TERMS_FAIL,
    });
  }
};
