import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetActiveTerms } from "../../../actions/termsActions";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { AcceptToS } from "../../../actions/authActions";
import { Alert } from "@mui/material";

const TermsOverlay = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tos = useSelector((state) => state.tos);
  const [checked, setChecked] = useState(false);
  const [showDialog, setShowDialog] = useState(true);

  useEffect(() => {
    dispatch(GetActiveTerms());
  }, [dispatch]);

  const accept = async () => {
    dispatch(AcceptToS());
    hide();
    navigate("/");
  };

  const hide = () => {
    setShowDialog(false);
  };

  const footer = (
    <div>
      <div className="field-checkbox justify-content-between">
        <div>
          <Checkbox
            inputId="agree"
            checked={checked}
            onChange={(e) => setChecked(e.checked)}
          />
          <label className="ml-2" htmlFor="agree">
            I agree to the Terms and Conditions
          </label>
        </div>
        <Button
          className="ml-2"
          label="Continue"
          onClick={accept}
          disabled={!checked}
        />
      </div>
    </div>
  );

  return (
    <div className="flex flex-column justify-content-center align-items-center">
      <Dialog
        header="Our Terms and Conditions have been updated"
        footer={footer}
        className="terms no-close"
        onHide={() => console.log("disabled")}
        visible={showDialog}
      >
        <div className="flex flex-auto">
          <Alert severity="info">
            To continue, please carefully read the updated Terms and Conditions
            and accept them by checking the box below.
          </Alert>
        </div>
        <div className="flex flex-auto scroll-box">
          <div
            style={{ maxWidth: "50rem", height: "15rem" }}
            className="scroll-horizontal scroll-vertical"
          >
            <div dangerouslySetInnerHTML={{ __html: tos.data.content }}></div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default TermsOverlay;
