import React, { useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { GetEnginesList } from "../../../actions/engineActions";
import {
  AddEngineToConfiguration,
  RemoveEngineToConfiguration,
} from "../../../actions/configurationActions";
import { sourceLanguageFlagTemplate } from "../../../Helpers";

const EngineList = (props) => {
  const mode = props.mode;
  const dispatch = useDispatch();

  const engines = useSelector((state) => state.engineList);
  const configurationCreateState = useSelector(
    (state) => state.configurationCreate
  );

  const rowsPerPageOptions = useMemo(() => [5, 10, 50], []);

  React.useEffect(() => {
    dispatch(GetEnginesList(rowsPerPageOptions[0], engines.page, 0, "", ""));
  }, [dispatch, engines.page, rowsPerPageOptions]);

  const onPage = (event) => {
    dispatch(
      GetEnginesList(event.rows, event.page, event.first, engines.query, "")
    );
  };

  const onFilter = (event) => {
    dispatch(
      GetEnginesList(
        engines.rows,
        engines.page,
        engines.first,
        event.filters,
        ""
      )
    );
  };

  const configContainsEngine = (engineId) => {
    return configurationCreateState.data.some(
      (engine) => engine.id === engineId
    );
  };

  const createConfigurationBody = (rowData) => {
    const onEngineChange = (e) => {
      if (e.checked)
        // Add engine
        dispatch(AddEngineToConfiguration(rowData));
      else {
        // Remove engine
        dispatch(RemoveEngineToConfiguration(rowData.id));
      }
    };

    return (
      <React.Fragment>
        <Checkbox
          onChange={onEngineChange}
          checked={configContainsEngine(rowData.id)}
        />
      </React.Fragment>
    );
  };

  /**
   * TODO refactor as DataTable in EngineAdvisory.js for multiselect.
   **/
  const loadEnginesTable = () => {
    return (
      <DataTable
        value={engines.data}
        lazy
        totalRecords={engines.count}
        rows={engines.rows}
        first={engines.offset}
        onPage={onPage}
        onFilter={onFilter}
        loading={engines.loading}
        emptyMessage="No engines found."
        filterDisplay="row"
        globalFilterFields={["source_language", "target_language"]}
        paginator
        responsiveLayout="scroll"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} engines"
        rowsPerPageOptions={rowsPerPageOptions}
        size="small"
      >
        {mode === "createConfiguration" && (
          <Column
            exportable={false}
            headerStyle={{ width: "4rem", textAlign: "center" }}
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
            body={createConfigurationBody}
          />
        )}

        <Column
          field="source_language"
          header="Source language"
          // style={{ width: "22rem" }}
          filter
          filterPlaceholder="Search by source language"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          body={(e) => sourceLanguageFlagTemplate(e, "source_language")}
        />
        <Column
          field="target_language"
          header="Target language"
          // style={{ width: "22rem" }}
          filter
          filterPlaceholder="Search by target language"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          body={(e) => sourceLanguageFlagTemplate(e, "target_language")}
        />
        <Column
          field="domain"
          header="Domain"
          filter
          filterPlaceholder="Search by domain"
          showFilterMatchModes={false}
        />
        <Column
          field="provider"
          header="Provider"
          filter
          filterPlaceholder="Search by providers"
          showFilterMatchModes={false}
        />
      </DataTable>
    );
  };

  return (
    <div>
      {mode !== "createConfiguration" && <h1>Engines</h1>}
      <div className="card">{loadEnginesTable()}</div>
    </div>
  );
};

export default EngineList;
