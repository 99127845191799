import { AdvisoryActionTypes } from "../constants/advisory-action-types";

const DefaultState = {
  loading: false,
  data: [],
  errorMsg: "",
};

const AdvisoryReducer = (state = DefaultState, action) => {
  switch (action.type) {
    case AdvisoryActionTypes.ADVISORY_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case AdvisoryActionTypes.ADVISORY_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: "Unable to fetch engine advisory",
      };
    case AdvisoryActionTypes.ADVISORY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: "",
      };
    default:
      return state;
  }
};

export default AdvisoryReducer;
