export const ProviderActionTypes = {
  PROVIDER_LIST_LOADING: "PROVIDERS/PROVIDER_LIST_LOADING",
  PROVIDER_LIST_SUCCESS: "PROVIDERS/PROVIDER_LIST_SUCCESS",
  PROVIDER_LIST_FAIL: "PROVIDERS/PROVIDER_LIST_FAIL",

  PROVIDER_MULTIPLE_LOADING: "PROVIDERS/PROVIDER_MULTIPLE_LOADING",
  PROVIDER_MULTIPLE_SUCCESS: "PROVIDERS/PROVIDER_MULTIPLE_SUCCESS",
  PROVIDER_MULTIPLE_FAIL: "PROVIDERS/PROVIDER_MULTIPLE_FAIL",

  PROVIDER_CHANGE_ENABLED_LOADING: "PROVIDERS/PROVIDER_CHANGE_ENABLED_LOADING",
  PROVIDER_CHANGE_ENABLED_SUCCESS: "PROVIDERS/PROVIDER_CHANGE_ENABLED_SUCCESS",
  PROVIDER_CHANGE_ENABLED_FAILED: "PROVIDERS/PROVIDER_CHANGE_ENABLED_FAILED",
};
