import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import React, { useRef } from "react";
import { Toast } from "primereact/toast";
import api from "../../interceptors/api";
import * as Yup from "yup";
import { useFormik } from "formik";

const Contact = () => {
  const toast = useRef(null);

  const getFormConfig = () => {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      company: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      company: Yup.string().required("Required"),
      message: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      sendMessage(
        values.name,
        values.email,
        values.company,
        values.message
      ).then((r) => formik.resetForm());
    },
  });

  const sendMessage = async (name, email, company, message) => {
    let bodyFormData = new FormData();
    bodyFormData.append("name", name);
    bodyFormData.append("email", email);
    bodyFormData.append("company", company);
    bodyFormData.append("message", message);

    await api
      .post(
        `/engine_advisory/api/request_human_validation`,
        bodyFormData,
        getFormConfig()
      )
      .then((res) => {
        toast.current.show({
          severity: "success",
          summary: "Message sent.",
          detail: "We will get in contact with you as soon as possible.",
        });
      })
      .catch((e) => {
        toast.current.show({
          severity: "fail",
          summary: "Message not sent.",
          detail: "Try again or send us an email directly.",
        });
      });
  };

  return (
    <div className="surface-section px-4 py-8 md:px-6 lg:px-8 bg-no-repeat bg-right-bottom">
      <div className="grid">
        <div className="col-12 md:col-6">
          <div className="p-fluid pr-0 md:pr-6">
            <form onSubmit={formik.handleSubmit}>
              <div className="field">
                <label htmlFor="name" className="font-medium">
                  Name*
                </label>
                <InputText
                  id="name"
                  className="py-3 px-2 text-lg"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.errors.name ? (
                  <div className="text-sm" style={{ color: "#d95b5b" }}>
                    {formik.errors.name}{" "}
                  </div>
                ) : null}
              </div>
              <div className="field">
                <label htmlFor="email" className="font-medium">
                  Email*
                </label>
                <InputText
                  id="email"
                  className="py-3 px-2 text-lg"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.errors.email ? (
                  <div className="text-sm" style={{ color: "#d95b5b" }}>
                    {formik.errors.email}{" "}
                  </div>
                ) : null}
              </div>
              <div className="field">
                <label htmlFor="company" className="font-medium">
                  Company*
                </label>
                <InputText
                  id="company"
                  className="py-3 px-2 text-lg"
                  value={formik.values.company}
                  onChange={formik.handleChange}
                />
                {formik.errors.company ? (
                  <div className="text-sm" style={{ color: "#d95b5b" }}>
                    {formik.errors.company}{" "}
                  </div>
                ) : null}
              </div>
              <div className="field">
                <label htmlFor="message" className="font-medium">
                  Message*
                </label>
                <InputTextarea
                  id="message"
                  rows={6}
                  autoResize
                  className="py-3 px-2 text-lg"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                />
                {formik.errors.message ? (
                  <div className="text-sm" style={{ color: "#d95b5b" }}>
                    {formik.errors.message}{" "}
                  </div>
                ) : null}
              </div>
              <Button
                label="Send Message"
                type="submit"
                icon="pi pi-send"
                className="w-auto"
                disabled={formik.isValidating || !formik.isValid}
              />
            </form>
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div className="text-900 text-2xl font-medium mb-6">
            Professional advisory
          </div>
          <div className="text-700 line-height-3 mb-6">
            Send us a message so we can help you further and discuss details.
          </div>
          <ul className="list-none p-0 m-0 mt-6 text-700">
            <li className="flex align-items-center mb-3">
              <i className="pi pi-phone mr-2"></i>
              <span>+32 9 335 22 00</span>
            </li>
            <li className="flex align-items-center mb-3">
              <i className="pi pi-map-marker mr-2"></i>
              <span>
                Amerikagebouw Kerkstraat 106 <br /> 9050 Gentbrugge - Belgium
              </span>
            </li>
            <li className="flex align-items-center">
              <i className="pi pi-inbox mr-2"></i>
              <span>info@crosslang.com</span>
            </li>
          </ul>
        </div>
      </div>
      <Toast ref={toast} />
    </div>
  );
};

export default Contact;
