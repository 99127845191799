import { AdvisoryActionTypes } from "../constants/advisory-action-types";
import api from "../interceptors/api";

export const GetAdvisory = (id) => async (dispatch) => {
  try {
    dispatch({
      type: AdvisoryActionTypes.ADVISORY_LOADING,
    });

    const res = await api.get(`/engine_advisory/api/engine_advisory/${id}`);

    dispatch({
      type: AdvisoryActionTypes.ADVISORY_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: AdvisoryActionTypes.ADVISORY_FAIL,
    });
  }
};

export const GetTestSet = (id) => async (dispatch) => {
  try {
    dispatch({
      type: AdvisoryActionTypes.TEST_SET_LOADING,
    });

    const res = await api.get(`/engine_advisory/api/test_set/${id}`);

    dispatch({
      type: AdvisoryActionTypes.TEST_SET_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: AdvisoryActionTypes.TEST_SET_FAIL,
    });
  }
};

export const GetTestSetTranslations = (id) => async (dispatch) => {
  try {
    dispatch({
      type: AdvisoryActionTypes.TEST_SET_TRANSLATIONS_LOADING,
    });

    const res = await api.get(
      `/engine_advisory/api/test_set_translations?testset_id=${id}`
    );

    dispatch({
      type: AdvisoryActionTypes.TEST_SET_TRANSLATIONS_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: AdvisoryActionTypes.TEST_SET_TRANSLATIONS_FAIL,
    });
  }
};

export const GetTestSetTranslationsFromEngineAdvisory =
  (id) => async (dispatch) => {
    try {
      dispatch({
        type: AdvisoryActionTypes.TEST_SET_TRANSLATIONS_LOADING,
      });

      const res = await api.get(
        `/engine_advisory/api/test_set_translations?engineadvisory_id=${id}`
      );

      dispatch({
        type: AdvisoryActionTypes.TEST_SET_TRANSLATIONS_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: AdvisoryActionTypes.TEST_SET_TRANSLATIONS_FAIL,
      });
    }
  };

export const ResetTestSetTranslations = () => async (dispatch) => {
  dispatch({ type: AdvisoryActionTypes.TEST_SET_TRANSLATIONS_RESET });
};
