import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import api from "../../interceptors/api";
import { useNavigate } from "react-router-dom";
import {
  dateTimeTemplate,
  getEngineName,
  unknownTemplate,
} from "../../utils/formatting";

export class DataTableLazy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      totalRecords: 0,
      rows: null,
      selectAll: false,
      selectedRows: null,
      selectedRepresentative: null,
      lazyParams: {
        first: 0,
        rows: 5,
        page: 1,
        sortField: null,
        sortOrder: null,
      },
    };

    this.loadLazyData = this.loadLazyData.bind(this);
    this.onPage = this.onPage.bind(this);

    this.dataService = props.dataService;
  }

  loadLazyData() {
    this.setState({ loading: true });

    this.dataService
      .getData({
        lazyEvent: JSON.stringify(this.state.lazyParams),
        ...this.state.lazyParams,
      })
      .then((data) => {
        this.setState({
          totalRecords: data.totalRecords,
          rows: data.rows,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  onPage(event) {
    this.setState({ lazyParams: event }, this.loadLazyData);
  }

  componentDidMount() {
    this.loadLazyData();
  }

  dataService() {
    // TO BE IMPLEMENTED IN SUBCLASS OR PASSED AS PROP
  }
}

class DataTableLazyEngineAdvisory extends DataTableLazy {
  constructor(props) {
    super(props);

    this.onRowSelect = props.onRowSelect.bind(this);
  }

  render() {
    return (
      <div>
        <DataTable
          value={this.state.rows}
          lazy
          dataKey="id"
          paginator
          first={this.state.lazyParams.first}
          totalRecords={this.state.totalRecords}
          onPage={this.onPage}
          loading={this.state.loading}
          // To be able to click on a row and open the details page
          selectionMode="single"
          onRowSelect={this.onRowSelect}
          // Pagination
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} advisories"
          rowsPerPageOptions={[5, 10, 50]}
          rows={this.state.lazyParams.rows}
        >
          <Column field="created_at" header={"Date"} body={dateTimeTemplate} />
          <Column field="filename" header={"Name"} body={unknownTemplate} />
          <Column field="source_language_name" header={"Source"} />
          <Column field="target_language_name" header={"Target"} />
          <Column
            field="best_engine"
            header={"Best Engine"}
            body={engineTemplate}
          />
          <Column field="status" header={"Status"} body={statusTemplate} />
        </DataTable>
      </div>
    );
  }
}

class EngineAdvisoryService {
  getData = async (params) => {
    let url = "/engine_advisory/api/engine_advisories";
    if (params.rows) {
      url += `?offset=${params.first}&rows=${params.rows}`;
    }
    // Timeout after 10 seconds
    const { data } = await api.get(url);

    return {
      totalRecords: data.count,
      rows: data.results,
    };
  };
}

const engineTemplate = (row, column) => {
  let engine = row[column.field];

  return engine && getEngineName(engine);
};

/**
 * Status:
 * - null: No status
 * - true: Success
 * - false: In progress
 */
const statusTemplate = (row, column) => {
  let status = row[column.field];

  let icon_map = {
    SUCCESS: "pi pi-check-circle",
    IN_PROGRESS: "pi pi-spinner pi-spin",
  };

  let color_map = {
    SUCCESS: "green",
    IN_PROGRESS: "orange",
  };

  let color = color_map[status] || "red";
  let icon = icon_map[status] || "pi pi-exclamation-circle";

  return <i className={icon} style={{ color: color }} />;
};

const EAOverview = () => {
  const navigate = useNavigate();

  const onRowSelect = (event) => {
    let eaId = event.data.id;

    const state = {};
    if (event.data.status) {
      state.step = 3;
    }

    navigate("/engine-advisory/automated/" + eaId, {
      state: state,
    });
  };

  return (
    <div>
      <h1>History of Automated Advisories</h1>

      <DataTableLazyEngineAdvisory
        dataService={new EngineAdvisoryService()}
        onRowSelect={onRowSelect}
      />
    </div>
  );
};

export default EAOverview;
