export const EngineActionTypes = {
  ENGINE_LIST_LOADING: "ENGINES/ENGINE_LIST_LOADING",
  ENGINE_LIST_SUCCESS: "ENGINES/ENGINE_LIST_SUCCESS",
  ENGINE_LIST_FAIL: "ENGINES/ENGINE_LIST_FAIL",

  FIRST_AVAILABLE_ENGINE_LOADING: "ENGINES/FIRST_AVAILABLE_ENGINE_LOADING",
  FIRST_AVAILABLE_ENGINE_SUCCESS: "ENGINES/FIRST_AVAILABLE_ENGINE_SUCCESS",
  FIRST_AVAILABLE_ENGINE_FAIL: "ENGINES/FIRST_AVAILABLE_ENGINE_FAIL",

  ENGINE_MULTIPLE_LOADING: "ENGINES/ENGINE_MULTIPLE_LOADING",
  ENGINE_MULTIPLE_SUCCESS: "ENGINES/ENGINE_MULTIPLE_SUCCESS",
  ENGINE_MULTIPLE_FAIL: "ENGINES/ENGINE_MULTIPLE_FAIL",

  AVAILABLE_SOURCE_LANGUAGES_LOADING: "SOURCE_LANGUAGES/LIST_LOADING",
  AVAILABLE_SOURCE_LANGUAGES_SUCCESS: "SOURCE_LANGUAGES/LIST_SUCCESS",
  AVAILABLE_SOURCE_LANGUAGES_FAIL: "SOURCE_LANGUAGES/LIST_FAIL",

  AVAILABLE_TARGET_LANGUAGES_LOADING: "TARGET_LANGUAGES/LIST_LOADING",
  AVAILABLE_TARGET_LANGUAGES_SUCCESS: "TARGET_LANGUAGES/LIST_SUCCESS",
  AVAILABLE_TARGET_LANGUAGES_FAIL: "TARGET_LANGUAGES/LIST_FAIL",
  AVAILABLE_TARGET_LANGUAGES_RESET: "TARGET_LANGUAGES/LIST_RESET",

  AVAILABLE_DOMAINS_LOADING: "DOMAINS/LIST_LOADING",
  AVAILABLE_DOMAINS_SUCCESS: "DOMAINS/LIST_SUCCESS",
  AVAILABLE_DOMAINS_FAIL: "DOMAINS/LIST_FAIL",
  AVAILABLE_DOMAINS_RESET: "DOMAINS/LIST_RESET",

  AVAILABLE_PROVIDERS_LOADING: "PROVIDERS/LIST_LOADING",
  AVAILABLE_PROVIDERS_SUCCESS: "PROVIDERS/LIST_SUCCESS",
  AVAILABLE_PROVIDERS_FAIL: "PROVIDERS/LIST_FAIL",
  AVAILABLE_PROVIDERS_RESET: "PROVIDERS/LIST_RESET",

  AVAILABLE_TAGS_LOADING: "TAGS/LIST_LOADING",
  AVAILABLE_TAGS_SUCCESS: "TAGS/LIST_SUCCESS",
  AVAILABLE_TAGS_FAIL: "TAGS/LIST_FAIL",

  SET_LAST_ENGINE: "ENGINES/SET_LAST_ENGINE",
};
