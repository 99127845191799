import { TmsActionTypes } from "../constants/tms-action-types";
import api from "../interceptors/api";

export const GetTmsList = () => async (dispatch) => {
  try {
    dispatch({
      type: TmsActionTypes.TMS_LIST_LOADING,
    });

    const res = await api.get(`/tms/api/tms`);

    dispatch({
      type: TmsActionTypes.TMS_LIST_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: TmsActionTypes.TMS_LIST_FAIL,
    });
  }
};

export const GetTms = (id) => async (dispatch) => {
  try {
    dispatch({
      type: TmsActionTypes.TMS_MULTIPLE_LOADING,
    });

    const res = await api.get(`/tms/api/tms/${id}`);

    dispatch({
      type: TmsActionTypes.TMS_MULTIPLE_SUCCESS,
      payload: res.data,
      tmsId: id,
    });
  } catch (e) {
    dispatch({
      type: TmsActionTypes.TMS_MULTIPLE_FAIL,
    });
  }
};
