import React from "react";

/**
 * @param {Object} props
 * @param {Number} props.activeStep Index of the active step
 *  Value between 0 and 3
 *  0: File uploaded
 *  1: Select engines
 *  2: Running
 *  3: Done
 * @param {Number} props.max_engine (Optional) Number of maximum engines to be selected (shown in details)
 * @param {Array} props.steps (optional) Array of objects with name and details
 */
const ProgressBarAdvisory = (props) => {
  // const [activeStep, setActiveStep] = React.useState(props.activeStep);
  const activeStep = props.activeStep;
  const max_engines = props.max_engines || 10; // Default value, see EngineAdvisory.js

  const steps = props.steps || [
    {
      name: "Test set/file",
      details: "Upload test set/file",
    },
    {
      name: "Engine selection",
      details: "Select up to " + max_engines + " MT Engines",
    },
    {
      name: "Processing",
      details: "Process advisory",
    },
    {
      name: "Results",
      details: "View results",
    },
  ];

  const renderStepActive = (step, icon) => {
    return (
      <div className="border-2 primary-border-color surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1">
        <i
          className={`pi pi-${icon} primary-color text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3`}
        ></i>
        <div>
          <div className="primary-color font-medium mb-1">
            {steps[step].name}
          </div>
          <span className="text-600 text-sm hidden md:block">
            {steps[step].details}
          </span>
        </div>
      </div>
    );
  };

  const renderStepDisabled = (step, icon) => {
    return (
      <div className="border-1 border-300 border-round p-3 surface-50 flex flex-column md:flex-row align-items-center z-1">
        <i
          className={`pi pi-${icon} text-600 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3`}
        ></i>
        <div>
          <div className="text-900 font-medium mb-1">{steps[step].name}</div>
          <span className="text-600 text-sm hidden md:block">
            {steps[step].details}
          </span>
        </div>
      </div>
    );
  };

  const renderStepPassed = (step, icon) => {
    return (
      <div className="border-1 border-300 border-round p-3 surface-card flex flex-column md:flex-row align-items-center z-1">
        <i
          className={`pi pi-${icon} primary-color text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3`}
        ></i>
        <div>
          <div className="text-900 font-medium mb-1">{steps[step].name}</div>
          <span className="text-600 text-sm hidden md:block">
            {steps[step].details}
          </span>
        </div>
      </div>
    );
  };

  const renderStepLine = () => {
    return (
      <div
        className="w-full absolute top-50 left-100 surface-300 hidden md:block"
        style={{ transform: "translateY(-50%)", height: "2px" }}
      ></div>
    );
  };

  return (
    <div className="py-5 px-6">
      <ul className="list-none p-0 m-0 flex flex-column md:flex-row">
        <li key="first-step" className="relative mr-0 md:mr-8 flex-auto">
          {activeStep === 0 && renderStepActive(0, "file")}
          {activeStep > 0 && renderStepPassed(0, "file")}
          {renderStepLine()}
        </li>
        <li key="second-step" className="relative mr-0 md:mr-8 flex-auto">
          {activeStep < 1 && renderStepDisabled(1, "cog")}
          {activeStep === 1 && renderStepActive(1, "cog")}
          {activeStep > 1 && renderStepPassed(1, "cog")}
          {renderStepLine()}
        </li>
        <li key="third-step" className="relative mr-0 md:mr-8 flex-auto">
          {activeStep < 2 && renderStepDisabled(2, "spinner")}
          {activeStep === 2 && renderStepActive(2, "spinner")}
          {activeStep > 2 && renderStepPassed(2, "spinner")}
          {renderStepLine()}
        </li>
        <li key="fourth-step" className="relative flex-auto">
          {activeStep < 3 && renderStepDisabled(3, "check-circle")}
          {activeStep === 3 && renderStepActive(3, "check-circle")}
        </li>
      </ul>
    </div>
  );
};

export default React.memo(ProgressBarAdvisory);
