import React from "react";
import { Button } from "primereact/button";
import EngineList from "./engines/EngineList";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { RemoveEngineToConfiguration } from "../../actions/configurationActions";
import { sourceLanguageFlagTemplate } from "../../Helpers";

export const getConfigurationName = (
  onChange = null,
  placeholder = "Configuration name",
  disabled = false
) => {
  if (onChange == null) {
    onChange = () => {};
  }

  return (
    <InputText
      className="inputfield mtg-input-full text-lg"
      id="name"
      name="name"
      type="text"
      autoComplete={"off"}
      placeholder={placeholder}
      onChange={onChange}
      // Don't grey out the field when disabled, but make it read-only
      disabled={disabled}
      style={{ opacity: 1 }}
      dir="auto"
    />
  );
};

export const getAddEngines = () => {
  return (
    <>
      <h4>Add engines</h4>
      {/*Explanation*/}
      <div className="mb-3 text-sm">
        Search for the engines you wish to add to this configuration and enable
        them by clicking the checkbox.
      </div>
      {/*Table*/}
      <EngineList mode="createConfiguration" />
    </>
  );
};

export const ConfigurationSelectedEngines = (
  configurationCreateState,
  dispatch
) => {
  const removeEngineActions = (rowData) => {
    return (
      <React.Fragment>
        <Button
          // Cross symbol
          icon="pi pi-times"
          // small, rounded, red, no background
          className="mtg-mini-btn p-button-rounded p-button-danger p-button-text"
          aria-label="Remove"
          onClick={() => {
            dispatch(RemoveEngineToConfiguration(rowData.id));
          }}
        />
      </React.Fragment>
    );
  };

  return (
    <>
      <h4>Selected engines ({configurationCreateState.data.length})</h4>
      <div className="mb-3 text-sm">
        This is the list of selected engines. To remove an engine from the
        configuration, click on the X button.
      </div>

      <DataTable
        value={configurationCreateState.data}
        emptyMessage="No engines added yet."
        size="small"
        scrollable // TODO issues with small width
        scrollHeight="300px" // TODO make this dynamic with "flex"
        // style={{ tableLayout: "fixed" }}
      >
        <Column
          exportable={false}
          headerStyle={{ width: "4rem", textAlign: "center" }}
          bodyStyle={{ textAlign: "center", overflow: "visible" }}
          body={removeEngineActions}
          style={{ flexGrow: 0, flexBasis: "4rem" }}
        />
        <Column
          field="source_language"
          header="Source language"
          // style={{ width: "22rem" }}
          style={{ flexGrow: 1 }}
          body={(e) => sourceLanguageFlagTemplate(e, "source_language")}
        />
        <Column
          field="target_language"
          header="Target language"
          // style={{ width: "22rem" }}
          style={{ flexGrow: 1 }}
          body={(e) => sourceLanguageFlagTemplate(e, "target_language")}
        />
        <Column field="domain" header="Domain" style={{ flexGrow: 1 }} />
        <Column field="provider" header="Provider" style={{ flexGrow: 1 }} />
      </DataTable>
    </>
  );
};

export const SaveConfigurationButton = (
  label = "Save",
  onClick = null,
  disabled = false
) => {
  if (onClick == null) {
    onClick = () => {};
  }

  return (
    <div className="p-3 col justify-content-center align-content-center mtg-input">
      <Button
        className="mtg-input"
        label={label}
        onClick={onClick}
        disabled={disabled}
      />
    </div>
  );
};
