import { ConfigurationActionTypes } from "../constants/configuration-action-types";

const DefaultState = {
  name: "", // Name of the configuration
  data: [], // List of engines
  success: undefined, // Whether the configuration was saved successfully
  id: undefined, // ID of the configuration, can be used to redirect to the configuration page
};

const ConfigurationCreateReducer = (state = DefaultState, action) => {
  state.success = undefined;
  state.id = undefined;

  switch (action.type) {
    case ConfigurationActionTypes.CONFIGURATION_ADD_ENGINE:
      return {
        ...state,
        // Check for duplicates and sort by source language, then target language, ...
        data: [...state.data, action.payload]
          .sort((a, b) => {
            return (
              a.source_language_display_name.localeCompare(
                b.source_language_display_name
              ) ||
              a.target_language_display_name.localeCompare(
                b.target_language_display_name
              ) ||
              a.provider.localeCompare(b.provider) ||
              a.domain.localeCompare(b.domain)
            );
          })
          .filter((item, index, self) => {
            return (
              index ===
              self.findIndex((t) => {
                return t.id === item.id;
              })
            );
          }),
      };
    case ConfigurationActionTypes.CONFIGURATION_REMOVE_ENGINE:
      return {
        ...state,
        data: state.data.filter((conf) => conf.id !== action.payload),
      };
    case ConfigurationActionTypes.CONFIGURATION_MODIFY_NAME:
      return {
        ...state,
        name: action.payload,
      };

    case ConfigurationActionTypes.CONFIGURATION_SAVE_SUCCESS:
      return {
        data: [],
        name: action.payload.name,
        success: true,
        id: action.payload.id,
      };

    case ConfigurationActionTypes.CONFIGURATION_SAVE_FAILED:
      return {
        ...state,
        msg: action.payload.msg,
        success: false,
      };

    case ConfigurationActionTypes.CONFIGURATION_CLEAR_VIEW:
      return {
        data: [],
        name: "",
        success: undefined,
      };
    default:
      return state;
  }
};

export default ConfigurationCreateReducer;
