import { EngineActionTypes } from "../constants/engine-action-types";

const DefaultState = {
  loading: false,
  data: [],
  errorMsg: "",
  count: 0,
  rows: 0,
  page: 1,
  offset: 0,
  query: {},
  all: false,
};

const EngineListReducer = (state = DefaultState, action) => {
  switch (action.type) {
    case EngineActionTypes.ENGINE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case EngineActionTypes.ENGINE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: "Unable to fetch engines",
        count: 0,
        rows: 0,
      };
    case EngineActionTypes.ENGINE_LIST_SUCCESS:
      let data = action.all ? action.payload : action.payload.results;
      let count = action.all ? action.payload.length : action.payload.count;
      return {
        ...state,
        loading: false,
        data: data,
        errorMsg: "",
        count: count,
        rows: action.rows,
        offset: action.offset,
        query: action.query,
        all: action.all,
      };
    default:
      return state;
  }
};

export default EngineListReducer;
