import React from "react";
import { GetProviderList } from "../../../actions/providerActions";
import { useDispatch, useSelector } from "react-redux";
import { DataView } from "primereact/dataview";
import { Message } from "primereact/message";
import { useNavigate } from "react-router-dom";
import { Tag } from "primereact/tag";

const ProviderList = () => {
  const dispatch = useDispatch();
  const providers = useSelector((state) => state.providerList);
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(GetProviderList());
  }, [dispatch]);

  const renderGridItem = (data) => {
    if (data.name !== "Omniscien" && data.name !== "Dummymt") {
      return (
        <div
          className="col-12 md:col-6 lg:col-4"
          onClick={() => navigate(`/providers/${data.id}`)}
        >
          <div className="product-grid-item m-5 p-6 shadow-2 border-round img-clickable">
            <div>
              <Message
                severity={data.enabled ? "success" : "error"}
                text={data.enabled ? "Active" : "Disabled"}
              />
            </div>
            <br />
            <div className="product-grid-item-content overflow-hidden">
              <img
                src={`${data.logo}`}
                onError={(e) =>
                  (e.target.src =
                    "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                }
                alt={data.name}
                height="100"
              />
              <h4>{data.name}</h4>
              <div className="text-truncate-6">{data.description}</div>
            </div>
            <br />
            <div>
              <b>Website:</b>{" "}
              <a
                href={data.website}
                className="mtg-link"
                rel="noreferrer"
                target="_blank"
              >
                {data.website}
              </a>
            </div>
            <div className="provider-tags">
              {data.tags.map((tag) => (
                <Tag key={tag} value={tag} className="mt-3 mr-1" />
              ))}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const itemTemplate = (product) => {
    if (!product) {
      return;
    }
    return renderGridItem(product);
  };

  return (
    <div>
      <h1>Providers</h1>
      <DataView
        value={providers.data}
        itemTemplate={itemTemplate}
        layout="grid"
        rows={3}
      />
    </div>
  );
};

export default ProviderList;
