import { ProviderActionTypes } from "../constants/provider-action-types";

const DefaultState = {
  loading: false,
  data: {},
  errorMsg: "",
};

const ProviderMultipleReducer = (state = DefaultState, action) => {
  switch (action.type) {
    case ProviderActionTypes.PROVIDER_MULTIPLE_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case ProviderActionTypes.PROVIDER_MULTIPLE_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: "Unable to find provider",
      };
    case ProviderActionTypes.PROVIDER_MULTIPLE_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: "",
        data: {
          ...state.data,
          [action.providerId]: action.payload,
        },
      };
    case ProviderActionTypes.PROVIDER_CHANGE_ENABLED_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: "",
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
      };
    default:
      return state;
  }
};

export default ProviderMultipleReducer;
