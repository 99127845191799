import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ChangeProviderEnabled,
  GetProvider,
} from "../../../actions/providerActions";
import _ from "lodash";
import { InputSwitch } from "primereact/inputswitch";
import { useParams } from "react-router-dom";
import { Tag } from "primereact/tag";

const ProviderDetail = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const providerState = useSelector((state) => state.provider);

  React.useEffect(() => {
    dispatch(GetProvider(id));
  }, [dispatch, id]);

  const getDetails = (id) => {
    if (!_.isEmpty(providerState.data[id])) {
      const provider = providerState.data[id];
      return (
        <>
          <div>
            <img
              src={`${provider.logo}`}
              onError={(e) =>
                (e.target.src =
                  "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
              }
              alt={provider.name}
              height="100"
            />
          </div>
          <div className="grid grid-nogutter border-top-1 surface-border pt-2">
            <div className="col-12 md:col-6 p-3">
              <div className="text-500 font-medium mb-2">Name</div>
              <div className="text-900">{provider.name}</div>
            </div>
            <div className="col-12 p-3">
              <div className="text-500 font-medium mb-2">Description</div>
              <div className="text-900 line-height-3">
                {provider.description}
              </div>
            </div>
            <div className="col-12 p-3">
              <div className="text-500 font-medium mb-2">Website</div>
              <div className="text-900 line-height-3">
                <a
                  className="mtg-link"
                  href={provider.website}
                  rel="noreferrer"
                  target="_blank"
                >
                  {provider.website}
                </a>
              </div>
            </div>
            <div className="col-12 p-3">
              <div className="text-500 font-medium mb-2">Tags</div>
              <div className="text-900 line-height-3">
                {provider.tags.map((tag) => (
                  <Tag key={tag} value={tag} className="mt-3 mr-1" />
                ))}
              </div>
            </div>
            {provider.can_enable && (
              <div className="col-12 p-3">
                <div className="text-500 font-medium mb-2">Enabled</div>
                <div className="text-900 line-height-3">
                  <InputSwitch
                    checked={provider.enabled}
                    onChange={(e) => {
                      dispatch(ChangeProviderEnabled(id, e.value));
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      );
    }
  };

  return <div>{getDetails(id)}</div>;
};

export default ProviderDetail;
