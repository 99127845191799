import { TranslationActionTypes } from "../constants/translation-action-types";
import api from "../interceptors/api";

export const GetTranslatedFileStatus = (jobId) => async (dispatch) => {
  try {
    const res = await api.get(`/catalogue/api/translate-document/${jobId}`);

    // Check if the translation is completed or failed or still in progress
    let translationActionType = TranslationActionTypes.TRANSLATION_LOADING;

    if (res.data.completed) {
      translationActionType = TranslationActionTypes.TRANSLATION_SUCCESS;
    } else if (res.data.failed) {
      translationActionType = TranslationActionTypes.TRANSLATION_FAIL;
    }

    dispatch({
      type: translationActionType,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: TranslationActionTypes.TRANSLATION_FAIL,
    });
  }
};

export const ResetTranslatedFileStatus = () => async (dispatch) => {
  dispatch({
    type: TranslationActionTypes.RESET_TRANSLATED_FILE_STATUS,
  });
};
