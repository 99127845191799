import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { getDateTime } from "../../utils/formatting";
import {
  ForgotPasswordRequest,
  ModifyForgotPasswordMessage,
} from "../../actions/authActions";
import { Dialog } from "primereact/dialog";

const Account = () => {
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const handleForgotPassword = () => {
    dispatch(ForgotPasswordRequest(auth.user));
    dispatch(ModifyForgotPasswordMessage(false));
  };

  const dialogFooter = (
    <div className="p-d-flex p-jc-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={() => {
          dispatch(ModifyForgotPasswordMessage(false));
        }}
      />
    </div>
  );

  return (
    <>
      <Dialog
        visible={auth.showForgotPasswordMessage}
        onHide={() => dispatch(ModifyForgotPasswordMessage(false))}
        position="top"
        footer={dialogFooter}
        showHeader={false}
        breakpoints={{ "960px": "80vw" }}
        style={{ width: "30vw" }}
      >
        <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3 center-text">
          <br />
          <i
            className="pi pi-check-circle"
            style={{ fontSize: "5rem", color: "var(--green-500)" }}
          ></i>
          <br />
          <h5>Password reset has been requested</h5>
          <br />
          <div className={"center-text"}>
            <p style={{ lineHeight: 1.5, textIndent: "0rem" }}>
              You will receive an email from us.
              <br />
              <br />
              Please check <b>{auth.user}</b> for further instructions.
            </p>
          </div>
        </div>
      </Dialog>
      <Card className="card-rounded" title="My Profile">
        <div className="grid">
          <div className="mb-1 col-12 md:col-3 lg:col-3">Email/Username:</div>
          <div className="font-medium col-12 md:col-9 lg:col-9">
            {auth.user}
          </div>
          <div className="mb-1 col-12 md:col-3 lg:col-3">Company:</div>
          <div className="font-medium col-12 md:col-9 lg:col-9">
            {auth.company}
          </div>
          <div className="mb-1 col-12 md:col-3 lg:col-3">Role:</div>
          <div className="font-medium col-12 md:col-9 lg:col-9">
            {Array.isArray(auth.role) ?
              auth.role.map(role => (
                <div>{role}</div>
              ))
              : auth.role}
          </div>
          <div className="mb-1 col-12 md:col-3 lg:col-3">Created at:</div>
          <div className="font-medium col-12 md:col-9 lg:col-9">
            {getDateTime(new Date(auth.created))}
          </div>
          <div className="mt-3 col-12 md:col-6 lg:col-6">
            <Button
              label="Reset Password"
              className="p-button-sm mt-2"
              icon="pi pi-key"
              onClick={handleForgotPassword}
            />
          </div>
        </div>
      </Card>
    </>
  );
};

export default Account;
