import { AdvisoryActionTypes } from "../constants/advisory-action-types";

const DefaultState = {
  loading: false,
  data: [],
  errorMsg: "",
};

const TestSetTranslationsReducer = (state = DefaultState, action) => {
  switch (action.type) {
    case AdvisoryActionTypes.TEST_SET_TRANSLATIONS_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case AdvisoryActionTypes.TEST_SET_TRANSLATIONS_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: "Unable to fetch test set translations",
      };
    case AdvisoryActionTypes.TEST_SET_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: "",
      };

    case AdvisoryActionTypes.TEST_SET_TRANSLATIONS_RESET:
      // Clear the list of test set translations with their progress
      return DefaultState;

    default:
      return state;
  }
};

export default TestSetTranslationsReducer;
