import { EngineActionTypes } from "../constants/engine-action-types";

const DefaultState = {
  loading: false,
  data: [],
  errorMsg: "",
};

const EngineProviderListReducer = (state = DefaultState, action) => {
  switch (action.type) {
    case EngineActionTypes.AVAILABLE_PROVIDERS_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case EngineActionTypes.AVAILABLE_PROVIDERS_FAIL:
      return {
        ...state,
        loading: false,
        data: [],
        errorMsg: "Unable to fetch providers",
      };
    case EngineActionTypes.AVAILABLE_PROVIDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: "",
      };
    case EngineActionTypes.AVAILABLE_PROVIDERS_RESET:
      return DefaultState;
    default:
      return state;
  }
};

export default EngineProviderListReducer;
