import axios from "axios";
import { setupInterceptorsTo } from "./interceptors";
import { baseUrl } from "../constants/axiosConf";

const api = setupInterceptorsTo(
  axios.create({
    baseURL: baseUrl,
  })
);

export default api;
