import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ClearConfigurationViews,
  GetConfiguration,
  IdleUpdateConfiguration,
  UpdateConfiguration,
} from "../../actions/configurationActions";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import {
  ConfigurationSelectedEngines,
  getAddEngines,
  getConfigurationName,
  SaveConfigurationButton,
} from "./ConfigurationShared";
import { Toast } from "primereact/toast";

const ConfigurationEdit = () => {
  const { id } = useParams();

  const configurationState = useSelector((state) => state.configuration);
  const configurationCreateState = useSelector(
    (state) => state.configurationCreate
  );
  const { status } = useSelector((state) => state.configurationUpdate);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef(null);

  React.useEffect(() => {
    dispatch(IdleUpdateConfiguration());
    // When leaving the page
    return () => {
      dispatch(IdleUpdateConfiguration());
    };
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(ClearConfigurationViews());
    dispatch(GetConfiguration(id, "edit"));
  }, [dispatch, id]);

  const updateConfiguration = (id, name, engines) => {
    if (name) {
      dispatch(UpdateConfiguration(id, name, engines));
    } else {
      dispatch(
        UpdateConfiguration(id, configurationState.data[id].name, engines)
      );
    }
  };

  /**
   * Show a toast message and go to details page if successful
   */
  React.useEffect(() => {
    if (status === "idle" || status === "loading") {
      return;
    }

    // Error
    if (status === "failed") {
      // FIXME toast.current.replace does not work correctly with the ZIndex
      toast.current.show({
        severity: "error",
        detail: "Failed to save changes.",
        life: 5000,
        id: "configurationUpdateFailed",
      });
    }

    if (status === "success") {
      toast.current.show({
        severity: "success",
        // summary: "Success",
        detail: "Changes saved successfully",
        id: "configurationUpdatedSuccess",
      });

      // Try to navigate to the updated configuration
      if (id) {
        // Navigate to the new configuration details page
        navigate("/config/" + id);
      } else {
        // Navigate back to the configuration list
        navigate("/config");
      }
    }
  }, [status, navigate, id]);

  const getView = (id) => {
    if (!_.isEmpty(configurationState.data[id])) {
      const configuration = configurationState.data[id];
      return (
        <div className={"p-3"}>
          <Toast ref={toast} />
          <h1>Edit configuration </h1>

          {getConfigurationName(null, configuration.name, true)}

          {ConfigurationSelectedEngines(configurationCreateState, dispatch)}
          {getAddEngines()}

          {SaveConfigurationButton("Save changes", () =>
            updateConfiguration(
              id,
              configurationCreateState.name,
              configurationCreateState.data
            )
          )}
        </div>
      );
    }
  };

  return <div>{getView(id)}</div>;
};

export default ConfigurationEdit;
