import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import {
  ForgotPasswordRequest,
  ModifyForgotPasswordMessage,
} from "../../actions/authActions";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";

const ForgotPassword = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      setFormData(values);
      dispatch(ForgotPasswordRequest(values.email));
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .email("Invalid email address")
        .required("Email is required"),
    }),
  });

  const dialogFooter = (
    <div className="p-d-flex p-jc-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={() => {
          dispatch(ModifyForgotPasswordMessage(false));
        }}
      />
    </div>
  );

  return (
    <div className="sign-up-page">
      {/*<Card className="occ-login-card">
                <h5>Forgot password</h5>*/}
      <img
        alt="CrossLang logo"
        src="./logo.png"
        className="d-inline-block login-page-logo"
      />
      <br />
      <Card className="occ-login-card grid">
        <h3>Forgot password</h3>
        <small>Don’t worry, happens to the best of us.</small>
        <br />
        <br />

        <Dialog
          visible={auth.showForgotPasswordMessage}
          onHide={() => dispatch(ModifyForgotPasswordMessage(false))}
          position="top"
          footer={dialogFooter}
          showHeader={false}
          breakpoints={{ "960px": "80vw" }}
          style={{ width: "30vw" }}
        >
          <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3 center-text">
            <br />
            <i
              className="pi pi-check-circle"
              style={{ fontSize: "5rem", color: "var(--green-500)" }}
            ></i>
            <br />
            <h5>Password reset has been requested</h5>
            <br />
            <div className={"center-text"}>
              <p style={{ lineHeight: 1.5, textIndent: "0rem" }}>
                If the submitted email exists in our database, you will receive
                an email from us.
                <br />
                <br />
                Please check <b>{formData.email}</b> for further instructions.
              </p>
            </div>
          </div>
        </Dialog>

        <form onSubmit={formik.handleSubmit}>
          <span className="p-float-label p-input-icon-right">
            <i className="pi pi-envelope" />
            <InputText
              id="email"
              name="email"
              value={formik.values.email}
              placeholder="Existing email..."
              onChange={formik.handleChange}
            />
          </span>
          {formik.errors.email ? <div>{formik.errors.email} </div> : null}
          <br />
          <br />

          <Button
            type="submit"
            label="Reset password"
            className="sign-in-button"
            // disabled={auth.loading}
          />
        </form>
      </Card>
    </div>
  );
};

export default ForgotPassword;
