import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import {
  ModifyRegistrationSuccessMessage,
  ModifyUserNotInvitedMessage,
  RegisterUser,
} from "../../actions/authActions";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, AlertTitle } from "@mui/material";
import api from "../../interceptors/api";

const SignupPage = () => {
  const [formData, setFormData] = useState({});
  const auth = useSelector((state) => state.auth);
  const [invitedUser, setInvitedUser] = useState(null);
  const [invitedUserFound, setInvitedUserFound] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { inviteToken } = useParams();

  React.useEffect(() => {
    const getInvitedUser = async () => {
      await api
        .get(`/auth/invite/` + inviteToken)
        .then((invUser) => {
          setInvitedUserFound(true);
          setInvitedUser(invUser.data);
        })
        .catch((err) => {
          setInvitedUserFound(false);
        });
    };
    getInvitedUser();
  }, [inviteToken]);

  const formik = useFormik({
    // enableReinitialize set to true to allow form to be re-initialized when the invited user is set
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: invitedUser ? invitedUser.email : "",
      password: "",
      accept: false,
    },
    validate: (data) => {
      let errors = {};

      if (!data.name) {
        errors.name = "Name is required.";
      }

      if (!data.email) {
        errors.email = "Email is required.";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = "Invalid email address. E.g. example@email.com";
      }

      if (!data.password) {
        errors.password = "Password is required.";
      }

      if (data.password.length > 0 && data.password.length < 8) {
        errors.password =
          "Password is too short. 8 characters is the minimum length.";
      }

      if (!data.accept) {
        errors.accept = "You need to agree to the terms and conditions.";
      }

      return errors;
    },
    onSubmit: (data) => {
      setFormData(data);
      dispatch(RegisterUser(data.email, data.password, data.name));

      formik.resetForm();
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const dialogFooterSuccess = (
    <div className="p-d-flex p-jc-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={() => {
          dispatch(ModifyRegistrationSuccessMessage(false));
          navigate("/");
        }}
      />
    </div>
  );

  const dialogFooterNotInvited = (
    <div className="p-d-flex p-jc-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={() => dispatch(ModifyUserNotInvitedMessage(false))}
      />
    </div>
  );
  const passwordHeader = <h6>Pick a password</h6>;
  const passwordFooter = (
    <React.Fragment>
      <Divider />
      <p className="p-mt-2">Suggestions</p>
      <ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: "1.5" }}>
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>Minimum 8 characters</li>
      </ul>
    </React.Fragment>
  );

  return (
    <div className="sign-up-page">
      <img
        alt="CrossLang logo"
        src="/logo.png"
        className="d-inline-block login-page-logo"
      />
      <br />

      {!invitedUserFound && (
        <Alert severity="error">
          <AlertTitle>Invalid invite link</AlertTitle>
          This invite link is invalid. Did you already register? Click{" "}
          <strong>
            <button className="btn-link" onClick={() => navigate("/login")}>
              here
            </button>
          </strong>{" "}
          to login.
        </Alert>
      )}

      {invitedUserFound && (
        <Card className="occ-login-card">
          <h3>Please sign up</h3>

          <br />

          <Dialog
            visible={auth.showSuccessMessage}
            onHide={() => dispatch(ModifyRegistrationSuccessMessage(false))}
            position="top"
            footer={dialogFooterSuccess}
            showHeader={false}
            breakpoints={{ "960px": "80vw" }}
            style={{ width: "30vw" }}
          >
            <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3 center-text">
              <br />
              <i
                className="pi pi-check-circle"
                style={{ fontSize: "5rem", color: "var(--green-500)" }}
              ></i>
              <br />
              <h5>Registration Successful!</h5>
              <br />
              <div className={"center-text"}>
                <p style={{ lineHeight: 1.5, textIndent: "0rem" }}>
                  Your account has been registered under the name{" "}
                  <b>{formData.name}</b>. <br />
                  <br />
                  Press 'OK' to proceed to the MT Gateway.
                  <br />
                  <br />
                  {/*Please check <b>{formData.email}</b> for activation instructions.*/}
                </p>
              </div>
            </div>
          </Dialog>

          <Dialog
            visible={auth.showNotInvitedMessage}
            onHide={() => dispatch(ModifyUserNotInvitedMessage(false))}
            position="top"
            footer={dialogFooterNotInvited}
            showHeader={false}
            breakpoints={{ "960px": "80vw" }}
            style={{ width: "30vw" }}
          >
            <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
              <i
                className="pi pi-ban"
                style={{ fontSize: "5rem", color: "#d95b5b" }}
              ></i>
              <br />
              <h5>You are not invited to register to the MT Gateway</h5>
              <br />
              <div className={"center-text"}>
                <p style={{ lineHeight: 1.5, textIndent: "0rem" }}>
                  Please contact your Company Manager to receive an invitation.
                </p>
              </div>
            </div>
          </Dialog>

          <div className="p-d-flex p-jc-center">
            <div style={{ minWidth: "400px" }}>
              <form onSubmit={formik.handleSubmit} className="p-fluid">
                <div className="p-field">
                  <span className="p-float-label p-input-icon-right">
                    <i className="pi pi-envelope" />
                    <InputText
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      disabled={true}
                      className={classNames({
                        "p-invalid": isFormFieldValid("email"),
                      })}
                    />
                  </span>
                  {getFormErrorMessage("email")}
                </div>

                <br />
                <br />

                <div className="p-field">
                  <span className="p-float-label">
                    <InputText
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      autoFocus
                      className={classNames({
                        "p-invalid": isFormFieldValid("name"),
                      })}
                    />
                    <label
                      htmlFor="name"
                      className={classNames({
                        "p-error": isFormFieldValid("name"),
                      })}
                    >
                      Name*
                    </label>
                  </span>
                  {getFormErrorMessage("name")}
                </div>

                <br />
                <br />

                <div className="p-field">
                  <span className="p-float-label">
                    <Password
                      id="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      toggleMask
                      className={classNames({
                        "p-invalid": isFormFieldValid("password"),
                      })}
                      header={passwordHeader}
                      footer={passwordFooter}
                    />
                    <label
                      htmlFor="password"
                      className={classNames({
                        "p-error": isFormFieldValid("password"),
                      })}
                    >
                      Password*
                    </label>
                  </span>
                  {getFormErrorMessage("password")}
                </div>

                <br />
                <br />

                <div className="p-field-checkbox">
                  <Checkbox
                    inputId="accept"
                    name="accept"
                    checked={formik.values.accept}
                    onChange={formik.handleChange}
                    className={classNames({
                      "p-invalid": isFormFieldValid("accept"),
                    })}
                  />
                  <label
                    htmlFor="accept"
                    className={classNames({
                      "p-error": isFormFieldValid("accept"),
                    })}
                  >
                    &nbsp;I agree to the&nbsp;
                    <a
                      href="/terms-and-conditions"
                      className="terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and Conditions
                    </a>
                    *
                  </label>
                </div>

                <br />

                <Button type="submit" label="Submit" className="p-mt-2" />
              </form>
            </div>
          </div>
        </Card>
      )}

      <br />
    </div>
  );
};

export default SignupPage;
