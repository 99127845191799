export const TranslationActionTypes = {
  TRANSLATION_LOADING: "TRANSLATION/TRANSLATION_LOADING",
  TRANSLATION_SUCCESS: "TRANSLATION/TRANSLATION_SUCCESS",
  TRANSLATION_FAIL: "TRANSLATION/TRANSLATION_FAIL",

  RESET_TRANSLATED_FILE_STATUS: "TRANSLATION/RESET_TRANSLATED_FILE_STATUS",

  ANALYSIS_LOADING: "TRANSLATION/ANALYSIS_LOADING",
  ANALYSIS_SUCCESS: "TRANSLATION/ANALYSIS_SUCCESS",
  ANALYSIS_FAIL: "TRANSLATION/ANALYSIS_FAIL",
};
