import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataView } from "primereact/dataview";
import { GetTmsList } from "../../../actions/tmsActions";
import { useNavigate } from "react-router-dom";

const TmsList = () => {
  const dispatch = useDispatch();
  const tms = useSelector((state) => state.tmsList);
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(GetTmsList());
  }, [dispatch]);

  const renderGridItem = (data) => {
    return (
      <div
        className="col-12 md:col-6 lg:col-4"
        onClick={() => navigate(`/help/tms/${data.id}`)}
      >
        <div className="product-grid-item m-5 p-6 shadow-2 border-round img-clickable">
          {/*<div>*/}
          {/*    <Message severity="success" text="Active"/>*/}
          {/*</div>*/}
          {/*<br/>*/}
          <div className="product-grid-item-content overflow-hidden">
            <img
              src={`${data.logo}`}
              onError={(e) =>
                (e.target.src =
                  "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
              }
              alt={data.name}
              height="100"
            />
            <h4>{data.name}</h4>
            <div className="text-truncate-3">{data.description}</div>
            <br />
            <div>
              <b>Website:&nbsp;</b>
              <a
                href={data.website}
                className="mtg-link"
                target="_blank"
                rel="noreferrer"
              >
                {data.website}
              </a>
            </div>
            {/*please check the location of the instructions tag for future reference*/}
            {data.instructions && (
              <div>
                <b>Instructions:</b>{" "}
                <span>
                  <a
                    href={data.instructions}
                    className="mtg-link"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {data.instructions.split("/").slice(-1)}
                  </a>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const itemTemplate = (product) => {
    if (!product) {
      return;
    }
    return renderGridItem(product);
  };

  return (
    <div>
      <h1>TMS</h1>
      <p>
        Below you find the various Translation Management Systems that the
        CrossLang MT Gateway supports. Click on an icon to read more information
        and find the instructions on how each one is linked to the MT Gateway's
        Configuration functionality.
      </p>
      <DataView
        value={tms.data}
        itemTemplate={itemTemplate}
        layout="grid"
        rows={3}
        emptyMessage=" " // Show nothing if there are no TMS
      />
    </div>
  );
};

export default TmsList;
