import React from "react";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useFormik } from "formik";
import {
  ModifyResetSuccessMessage,
  ResetPassword,
} from "../../actions/authActions";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog } from "primereact/dialog";

const ResetPasswordComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  let { id } = useParams();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    onSubmit: (values) => {
      dispatch(ResetPassword(id, values.password));
    },
    validationSchema: Yup.object({
      password: Yup.string().trim().required("Password is required").min(8),
      confirm_password: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    }),
  });

  const dialogFooter = (
    <div className="p-d-flex p-jc-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={() => {
          dispatch(ModifyResetSuccessMessage(false));
          navigate("/login");
        }}
      />
    </div>
  );

  return (
    <div className="occ-login">
      <img
        alt="CrossLang logo"
        src="/logo.png"
        className="d-inline-block login-page-logo"
      />
      <br />
      <Card className="occ-login-card grid">
        <h3>Reset password</h3>

        <Dialog
          visible={auth.showPasswordResetSuccessMessage}
          onHide={() => dispatch(ModifyResetSuccessMessage(false))}
          position="top"
          footer={dialogFooter}
          showHeader={false}
          breakpoints={{ "960px": "80vw" }}
          style={{ width: "30vw" }}
        >
          <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3 center-text">
            <br />
            <i
              className="pi pi-check-circle"
              style={{ fontSize: "5rem", color: "var(--green-500)" }}
            ></i>
            <br />
            <h5>Password updated</h5>
            <br />
            <div className={"center-text"}>
              <p style={{ lineHeight: 1.5, textIndent: "0rem" }}>
                Your password has been successfully updated.
                <br />
                <br />
                Press 'OK' to proceed to the MT Gateway login page.
                <br />
                <br />
                {/*Please check <b>{formData.email}</b> for activation instructions.*/}
              </p>
            </div>
          </div>
        </Dialog>

        <form onSubmit={formik.handleSubmit}>
          <Password
            id="password"
            name="password"
            placeholder="New password"
            feedback={false}
            toggleMask
            value={formik.values.password}
            onChange={formik.handleChange}
            className="col-12 p-0 mt-2"
          />
          {formik.errors.password ? <div>{formik.errors.password} </div> : null}
          <br />
          <Password
            id="confirm_password"
            name="confirm_password"
            placeholder="Confirm new password"
            feedback={false}
            toggleMask
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            className="col-12 p-0 mt-2"
          />
          {formik.errors.confirm_password ? (
            <div className="text-sm" style={{ color: "#d95b5b" }}>
              {formik.errors.confirm_password}{" "}
            </div>
          ) : null}

          <br />
          <br />

          <Button
            type="submit"
            label="Reset password"
            className="sign-in-button"
          />
        </form>
      </Card>
    </div>
  );
};

export default ResetPasswordComponent;
