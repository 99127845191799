import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ChangeConfigurationEnabled,
  GetConfiguration,
} from "../../actions/configurationActions";
import { Tag } from "primereact/tag";
import _ from "lodash";
import { InputSwitch } from "primereact/inputswitch";
import { mtApiBaseUrl } from "../../constants/axiosConf";
import { Button } from "primereact/button";
import { useParams } from "react-router-dom";
import { getDateTime } from "../../utils/formatting";

const ConfigurationDetail = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const configurationState = useSelector((state) => state.configuration);

  React.useEffect(() => {
    dispatch(GetConfiguration(id, "view"));
  }, [dispatch, id]);

  function copyToClipBoard(content) {
    navigator.clipboard.writeText(content);
  }

  const getDetails = (id) => {
    const getConfigurationDetails = (id) => {
      if (!_.isEmpty(configurationState.data[id])) {
        const configuration = configurationState.data[id];
        return (
          <>
            <div className="text-500 mb-5">
              All the credentials and details of your configuration can be found
              below.
            </div>
            <ul className="list-none p-0 m-0 border-top-1 surface-border">
              <li className="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-2 font-medium">Name</div>
                <div className="text-900 w-full md:w-10">
                  {configuration.name}
                </div>
              </li>
              <li className="flex align-items-center py-3 px-2 flex-wrap">
                <div className="text-500 w-full md:w-2 font-medium">
                  Engines
                </div>
                <div className="text-900 w-full md:w-10">
                  {!_.isEmpty(configuration.engines) && (
                    <>
                      {configuration.engines.map((engine) => {
                        return (
                          <Tag
                            key={engine.id}
                            className="mr-2"
                            value={`${engine.source_language.toLowerCase()}-${engine.target_language.toLowerCase()} (${
                              engine.provider
                            },${engine.domain})`}
                            rounded
                          ></Tag>
                        );
                      })}
                    </>
                  )}
                </div>
              </li>
              <li className="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-2 font-medium">
                  API URL
                </div>
                <div className="text-900 line-height-3">
                  <a
                    className="mtg-link"
                    href={mtApiBaseUrl}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {mtApiBaseUrl}
                  </a>
                </div>
                <Button
                  icon="pi pi-copy"
                  label="Copy API URL"
                  className="p-button-outlined mt-1 md:mt-0 ml-1"
                  onClick={(_) => {
                    copyToClipBoard(mtApiBaseUrl);
                  }}
                />
              </li>
              <li className="flex align-items-center py-3 px-2 flex-wrap">
                <div className="text-500 w-full md:w-2 font-medium">
                  Client ID
                </div>
                <div className="text-900 line-height-3">
                  {configuration.clientId}
                </div>
                <Button
                  icon="pi pi-copy"
                  label="Copy Client ID"
                  className="p-button-outlined mt-1 md:mt-0 ml-1"
                  onClick={(_) => {
                    copyToClipBoard(configuration.clientId);
                  }}
                />
              </li>
              <li className="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-2 font-medium">
                  Client Secret
                </div>
                <div className="text-900 line-height-3">
                  {configuration.clientSecret}
                </div>
                <Button
                  icon="pi pi-copy"
                  label="Copy Client Secret"
                  className="p-button-outlined mt-1 md:mt-0 ml-1"
                  onClick={(_) => {
                    copyToClipBoard(configuration.clientSecret);
                  }}
                />
              </li>

              <li className="flex align-items-center py-3 px-2 flex-wrap">
                <div className="text-500 w-full md:w-2 font-medium">
                  Enabled
                </div>
                <div className="text-900 w-full md:w-10">
                  <InputSwitch
                    checked={configuration.enabled}
                    onChange={(e) => {
                      dispatch(ChangeConfigurationEnabled(id, e.value));
                    }}
                  />
                </div>
              </li>
              <li className="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                <div className="text-500 w-full md:w-2 font-medium">
                  Last usage
                </div>
                <div className="text-900 w-full md:w-10 line-height-3">
                  {configuration.last_used
                    ? getDateTime(new Date(configuration.last_used))
                    : "Not used yet"}
                </div>
              </li>

              <li className="flex align-items-center py-3 px-2 flex-wrap">
                <div className="text-500 w-full md:w-2 font-medium">
                  Instructions
                </div>
                <div className="text-900 w-full md:w-10">
                  <div className="grid mt-0 mr-0">
                    <div className="col-12 md:col-12">
                      <div className="p-3 border-1 surface-border border-round surface-card">
                        <div className="text-900 mb-2">
                          <span className="font-medium mtg-link">
                            <a href={"/help/tms"}>
                              {
                                "Click here for instructions on how to link this configuration in your TMS of choice"
                              }
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </>
        );
      } else {
        return (
          <div className="text-500 mb-5">
            We were unable to find the configuration you are looking for. Please
            select a different configuration or contact support.
          </div>
        );
      }
    };

    return (
      <>
        <div className="surface-card p-4 shadow-2 border-round">
          <div className="font-medium text-3xl text-900 mb-3">
            Configuration Details
          </div>

          {getConfigurationDetails(id)}
        </div>
      </>
    );
  };

  return <div>{getDetails(id)}</div>;
};

export default ConfigurationDetail;
