export const ConfigurationActionTypes = {
  CONFIGURATION_LIST_LOADING: "CONFIGURATIONS/CONFIGURATION_LIST_LOADING",
  CONFIGURATION_LIST_SUCCESS: "CONFIGURATIONS/CONFIGURATION_LIST_SUCCESS",
  CONFIGURATION_LIST_FAIL: "CONFIGURATIONS/CONFIGURATION_LIST_FAIL",

  CONFIGURATION_MULTIPLE_LOADING:
    "CONFIGURATIONS/CONFIGURATION_MULTIPLE_LOADING",
  CONFIGURATION_MULTIPLE_SUCCESS:
    "CONFIGURATIONS/CONFIGURATION_MULTIPLE_SUCCESS",
  CONFIGURATION_MULTIPLE_FAIL: "CONFIGURATIONS/CONFIGURATION_MULTIPLE_FAIL",

  CONFIGURATION_CHANGE_ENABLED_LOADING:
    "CONFIGURATIONS/CONFIGURATION_CHANGE_ENABLED_LOADING",
  CONFIGURATION_CHANGE_ENABLED_SUCCESS:
    "CONFIGURATIONS/CONFIGURATION_CHANGE_ENABLED_SUCCESS",
  CONFIGURATION_CHANGE_ENABLED_FAILED:
    "CONFIGURATIONS/CONFIGURATION_CHANGE_ENABLED_FAILED",

  CONFIGURATION_ADD_ENGINE: "CONFIGURATIONS/CONFIGURATION_ADD_ENGINE",
  CONFIGURATION_REMOVE_ENGINE: "CONFIGURATIONS/CONFIGURATION_REMOVE_ENGINE",
  CONFIGURATION_MODIFY_NAME: "CONFIGURATIONS/CONFIGURATION_MODIFY_NAME",

  CONFIGURATION_GET_CREDENTIALS_LOADING:
    "CONFIGURATIONS/CONFIGURATION_GET_CREDENTIALS_LOADING",
  CONFIGURATION_GET_CREDENTIALS_SUCCESS:
    "CONFIGURATIONS/CONFIGURATION_GET_CREDENTIALS_SUCCESS",
  CONFIGURATION_GET_CREDENTIALS_FAILED:
    "CONFIGURATIONS/CONFIGURATION_GET_CREDENTIALS_FAILED",

  CONFIGURATION_SAVE_LOADING: "CONFIGURATIONS/CONFIGURATION_SAVE_LOADING",
  CONFIGURATION_SAVE_SUCCESS: "CONFIGURATIONS/CONFIGURATION_SAVE_SUCCESS",
  CONFIGURATION_SAVE_FAILED: "CONFIGURATIONS/CONFIGURATION_SAVE_FAILED",

  CONFIGURATION_DELETE_LOADING: "CONFIGURATIONS/CONFIGURATION_DELETE_LOADING",
  CONFIGURATION_DELETE_SUCCESS: "CONFIGURATIONS/CONFIGURATION_DELETE_SUCCESS",
  CONFIGURATION_DELETE_FAILED: "CONFIGURATIONS/CONFIGURATION_DELETE_FAILED",

  CONFIGURATION_UPDATE_LOADING: "CONFIGURATIONS/CONFIGURATION_UPDATE_LOADING",
  CONFIGURATION_UPDATE_SUCCESS: "CONFIGURATIONS/CONFIGURATION_UPDATE_SUCCESS",
  CONFIGURATION_UPDATE_FAILED: "CONFIGURATIONS/CONFIGURATION_UPDATE_FAILED",
  CONFIGURATION_UPDATE_IDLE: "CONFIGURATIONS/CONFIGURATION_UPDATE_IDLE",

  CONFIGURATION_CLEAR_VIEW: "CONFIGURATIONS/CONFIGURATION_CLEAR_VIEW",
};
