import { TranslationActionTypes } from "../constants/translation-action-types";

const DefaultState = {
  loading: false,
  data: {},
  errorMsg: "",
  done: false, // Set to true when the translation is completed or failed
};

const TranslatedFileStatusReducer = (state = DefaultState, action) => {
  switch (action.type) {
    case TranslationActionTypes.TRANSLATION_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: "",
        data: action.payload,
        done: false,
      };
    case TranslationActionTypes.TRANSLATION_FAIL:
      return {
        ...state,
        loading: false,
        data: { failed: true },
        errorMsg: "Unable to find translation",
        done: true,
      };
    case TranslationActionTypes.TRANSLATION_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: "",
        data: action.payload,
        done: true,
      };
    case TranslationActionTypes.RESET_TRANSLATED_FILE_STATUS:
      return {
        ...state,
        ...DefaultState,
      };
    default:
      return state;
  }
};

export default TranslatedFileStatusReducer;
