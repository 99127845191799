import { ConfigurationActionTypes } from "../constants/configuration-action-types";
import api from "../interceptors/api";

export const GetConfigurationsList =
  (rows, page, offset, query) => async (dispatch) => {
    try {
      // the query might have a name key, but it might also be a string
      const filter = {
        name: query.name ? query.name.value : query,
      };

      dispatch({
        type: ConfigurationActionTypes.CONFIGURATION_LIST_LOADING,
        filter: filter,
      });

      const res = await api.get(
        `/catalogue/api/configurations?rows=${rows}&offset=${offset}&name=${filter.name}`
      );

      dispatch({
        type: ConfigurationActionTypes.CONFIGURATION_LIST_SUCCESS,
        payload: res.data,
        rows: rows,
        offset: offset,
        filter: filter.name,
      });
    } catch (e) {
      dispatch({
        type: ConfigurationActionTypes.CONFIGURATION_LIST_FAIL,
      });
    }
  };

export const GetConfigurationsListAll = () => async (dispatch) => {
  try {
    dispatch({
      type: ConfigurationActionTypes.CONFIGURATION_LIST_LOADING,
    });

    let rows = Number.MAX_SAFE_INTEGER;
    let offset = 0;

    const res = await api.get(
      `/catalogue/api/configurations?rows=${rows}&offset=${offset}`
    );

    dispatch({
      type: ConfigurationActionTypes.CONFIGURATION_LIST_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: ConfigurationActionTypes.CONFIGURATION_LIST_FAIL,
    });
  }
};

export const GetConfiguration = (id, mode) => async (dispatch) => {
  try {
    dispatch({
      type: ConfigurationActionTypes.CONFIGURATION_MULTIPLE_LOADING,
    });

    const res = await api.get(`/catalogue/api/configurations/${id}`);

    dispatch({
      type: ConfigurationActionTypes.CONFIGURATION_MULTIPLE_SUCCESS,
      payload: res.data,
      configurationId: id,
    });

    if (mode === "edit") {
      res.data.engines.forEach((eng) => {
        dispatch({
          type: ConfigurationActionTypes.CONFIGURATION_ADD_ENGINE,
          payload: eng,
          configurationId: id,
        });
      });
    }
  } catch (e) {
    dispatch({
      type: ConfigurationActionTypes.CONFIGURATION_MULTIPLE_FAIL,
    });
  }
};

export const ChangeConfigurationEnabled = (id, value) => async (dispatch) => {
  try {
    dispatch({
      type: ConfigurationActionTypes.CONFIGURATION_CHANGE_ENABLED_LOADING,
    });

    const data = {
      enabled: value,
    };

    const res = await api.put(`/catalogue/api/configurations/${id}`, data);

    dispatch({
      type: ConfigurationActionTypes.CONFIGURATION_CHANGE_ENABLED_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: ConfigurationActionTypes.CONFIGURATION_CHANGE_ENABLED_FAILED,
    });
  }
};

export const AddEngineToConfiguration = (engine) => async (dispatch) => {
  dispatch({
    type: ConfigurationActionTypes.CONFIGURATION_ADD_ENGINE,
    payload: engine,
  });
};

/**
 * Removes an engine from the configuration
 * @param id is the engine id, e.g. rowData.id
 **/
export const RemoveEngineToConfiguration = (id) => async (dispatch) => {
  dispatch({
    type: ConfigurationActionTypes.CONFIGURATION_REMOVE_ENGINE,
    payload: id,
  });
};

export const ModifyNewConfigurationName = (name) => async (dispatch) => {
  dispatch({
    type: ConfigurationActionTypes.CONFIGURATION_MODIFY_NAME,
    payload: name,
  });
};

export const SaveConfiguration = (name, engines) => async (dispatch) => {
  try {
    dispatch({
      type: ConfigurationActionTypes.CONFIGURATION_SAVE_LOADING,
    });

    const res = await api.post(`/catalogue/api/configurations`, {
      name: name,
      engines: engines,
      enabled: true,
    });

    dispatch({
      type: ConfigurationActionTypes.CONFIGURATION_SAVE_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    let msg =
      "Unable to save configuration. Please try again later or contact support.";
    if (e.response.status === 409) {
      msg =
        "A configuration with this name already exists. Please make sure you have entered a unique name";
    }

    dispatch({
      type: ConfigurationActionTypes.CONFIGURATION_SAVE_FAILED,
      payload: { msg: msg },
    });
  }
};

export const DeleteConfiguration = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ConfigurationActionTypes.CONFIGURATION_DELETE_LOADING,
    });

    await api.delete(`/catalogue/api/configurations/${id}`);

    dispatch({
      type: ConfigurationActionTypes.CONFIGURATION_DELETE_SUCCESS,
      payload: id,
    });
  } catch (e) {
    dispatch({
      type: ConfigurationActionTypes.CONFIGURATION_DELETE_FAILED,
    });
  }
};

export const UpdateConfiguration = (id, name, engines) => async (dispatch) => {
  try {
    dispatch({
      type: ConfigurationActionTypes.CONFIGURATION_UPDATE_LOADING,
    });

    const res = await api.put(`/catalogue/api/configurations/${id}`, {
      id: id,
      name: name,
      engines: engines,
    });

    dispatch({
      type: ConfigurationActionTypes.CONFIGURATION_UPDATE_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: ConfigurationActionTypes.CONFIGURATION_UPDATE_FAILED,
    });
  }
};

export const IdleUpdateConfiguration = () => async (dispatch) => {
  dispatch({
    type: ConfigurationActionTypes.CONFIGURATION_UPDATE_IDLE,
  });
};

export const ClearConfigurationViews = () => async (dispatch) => {
  dispatch({
    type: ConfigurationActionTypes.CONFIGURATION_CLEAR_VIEW,
  });
};
