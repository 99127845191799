import { Navigate, Route, Routes } from "react-router-dom";
import Header from "./containers/core/Header";

import LoginPage from "./containers/core/LoginPage";
import React, { useEffect } from "react";
import { LoadUser, LoginWithIP } from "./actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { ScrollTop } from "primereact/scrolltop";
import Footer from "./containers/core/Footer";
import Dashboard from "./containers/Dashboard";
import SignupPage from "./containers/core/SignupPage";
import Account from "./containers/core/Account";
import ResetPasswordComponent from "./containers/core/ResetPasswordComponent";
import ForgotPassword from "./containers/core/ForgotPassword";
import EngineList from "./containers/connections/engines/EngineList";

import "./App.scss";
import "./assets/css/demo/flags.css";
import "./assets/css/demo/lang-flags.css";
import ProviderList from "./containers/connections/providers/ProviderList";
import TmsList from "./containers/connections/tms/TmsList";
import ProviderDetail from "./containers/connections/providers/ProviderDetail";
import UserManagement from "./containers/settings/UserManagement";
import TmsDetail from "./containers/connections/tms/TmsDetail";
import ConfigurationList from "./containers/connections/ConfigurationList";
import ConfigurationDetail from "./containers/connections/ConfigurationDetail";
import Translate from "./containers/translate/Translate";
import ConfigurationCreate from "./containers/connections/ConfigurationCreate";
import ConfigurationEdit from "./containers/connections/ConfigurationEdit";
import LandingPage from "./containers/engine_advisory/LandingPage";
import EngineAdvisory from "./containers/engine_advisory/EngineAdvisory";
import Contact from "./containers/engine_advisory/Contact";
import FaqList from "./containers/connections/help/Faq";
import PrivacyList from "./containers/connections/help/PrivacyStatement";
import TermsList from "./containers/connections/help/TermsConditions";
import TermsOverlay from "./containers/connections/help/TermsConditionsOverlay";
import MissingRoute from "./containers/MissingRoute";
import CookieConsent from "./containers/core/CookieConsent";
import PrivateRoute from "./containers/core/PrivateRoute";
import { useExternalScript } from "./containers/utils/useExternalScript";
import EADetails from "./containers/engine_advisory/EADetails";
import EAOverview from "./containers/engine_advisory/EAOverview";
import Forbidden from "./containers/Forbidden";
import {MANAGE_USERS, TRANSLATE, USE_ADVISORY, USE_CONFIGURATIONS, USE_OCR} from "./utils/permissions";

function App() {
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  // status embed script for https://crosslangmt-status.statuspage.io/
  const statusScriptUrl = "https://bjjgsknx4v4m.statuspage.io/embed/script.js";
  // load with hook
  useExternalScript(statusScriptUrl);

  useEffect(() => {
    // cookie consent dark theme
    document.body.classList.toggle("c_darkmode");

    if (auth.isAuthenticated) {
      dispatch(LoadUser());
    } else {
      dispatch(LoginWithIP());
    }
  }, [dispatch, auth.isAuthenticated]);

  return (
    <div className="App min-h-screen flex flex-column">
      <Header />

      <div className="p-5 flex flex-column flex-auto">
        <div className="border-2 border-dashed surface-border border-round surface-section flex-auto p-3">
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute requiredPermission={TRANSLATE}>
                  <Translate />
                </PrivateRoute>
              }
            />
            <Route
              path={"/profile"}
              element={
                <PrivateRoute>
                  <Account />
                </PrivateRoute>
              }
            />
            <Route
              path={"/user-management"}
              element={
                <PrivateRoute requiredPermission={MANAGE_USERS}>
                  <UserManagement />
                </PrivateRoute>
              }
            />

            <Route
              path={"/engines"}
              element={
                <PrivateRoute>
                  <EngineList />
                </PrivateRoute>
              }
            />

            <Route
              path={"/config"}
              element={
                <PrivateRoute requiredPermission={USE_CONFIGURATIONS}>
                  <ConfigurationList />
                </PrivateRoute>
              }
            />
            <Route
              path={"/config/create"}
              element={
                <PrivateRoute requiredPermission={USE_CONFIGURATIONS}>
                  <ConfigurationCreate />
                </PrivateRoute>
              }
            />
            <Route
              path={"/config/:id"}
              element={
                <PrivateRoute requiredPermission={USE_CONFIGURATIONS}>
                  <ConfigurationDetail />
                </PrivateRoute>
              }
            />
            <Route
              path={"/config/:id/edit"}
              element={
                <PrivateRoute requiredPermission={USE_CONFIGURATIONS}>
                  <ConfigurationEdit />
                </PrivateRoute>
              }
            />

            <Route
              path={"/providers"}
              element={
                <PrivateRoute>
                  <ProviderList />
                </PrivateRoute>
              }
            />
            <Route
              path={"/providers/:id"}
              element={
                <PrivateRoute>
                  <ProviderDetail />
                </PrivateRoute>
              }
            />

            <Route
              path={"/help/faq"}
              element={
                <PrivateRoute>
                  <FaqList />
                </PrivateRoute>
              }
            />
            <Route
              path={"/privacy-statement"}
              element={
                <PrivateRoute>
                  <PrivacyList />
                </PrivateRoute>
              }
            />
            <Route
              path={"/terms-and-conditions"}
              element={
                <PrivateRoute>
                  <TermsList />
                </PrivateRoute>
              }
            />
            <Route
              path={"/terms-and-conditions/accept"}
              element={
                <PrivateRoute>
                  <TermsOverlay />
                </PrivateRoute>
              }
            />
            <Route
              path={"/help"}
              element={<Navigate to={"/help/tms"} replace={true} state={{}} />}
            />
            <Route
              path={"/help/tms"}
              element={
                <PrivateRoute>
                  <TmsList />
                </PrivateRoute>
              }
            />
            <Route
              path={"/help/tms/:id"}
              element={
                <PrivateRoute>
                  <TmsDetail />
                </PrivateRoute>
              }
            />

            <Route
              path={"/dashboard"}
              element={
                <PrivateRoute requiredPermission={MANAGE_USERS}>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path={"/engine-advisory"}
              element={
                <PrivateRoute requiredPermission={USE_ADVISORY}>
                  <LandingPage />
                </PrivateRoute>
              }
            />
            <Route
              path={"/engine-advisory/automated"}
              element={
                <PrivateRoute requiredPermission={USE_ADVISORY}>
                  <EAOverview />
                </PrivateRoute>
              }
            />
            <Route
              path={"/engine-advisory/automated/new"}
              element={
                <PrivateRoute requiredPermission={USE_ADVISORY}>
                  <EngineAdvisory />
                </PrivateRoute>
              }
            />
            <Route
              path={"/engine-advisory/automated/:id"}
              element={
                <PrivateRoute requiredPermission={USE_ADVISORY}>
                  <EADetails />
                </PrivateRoute>
              }
            />
            <Route
              path={"/engine-advisory/contact"}
              element={
                <PrivateRoute requiredPermission={USE_ADVISORY}>
                  <Contact />
                </PrivateRoute>
              }
            />

            <Route
              path={"/not-implemented-yet"}
              element={
                <PrivateRoute>
                  <MissingRoute />
                </PrivateRoute>
              }
            />

            <Route path={"/login"} element={<LoginPage />} />
            <Route path={"/signup"} element={<SignupPage />} />
            <Route path={"/forgot-password"} element={<ForgotPassword />} />
            <Route path={"/signup/:inviteToken"} element={<SignupPage />} />
            <Route
              path={"/reset-password/:id"}
              element={<ResetPasswordComponent />}
            />
            <Route path={"/forbidden"} element={<Forbidden />} />

            <Route path="*" element={<MissingRoute />} />
          </Routes>
        </div>
      </div>

      <CookieConsent />
      <Footer />
      <ScrollTop />
    </div>
  );
}

export default App;
