import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children, requiredPermission }) => {
  const auth = useSelector((state) => state.auth);

  const parseLocalStorageValue = (value) => {
    return value === "true";
  };

  // locale storage only stores strings, so we need to parse the value
  const isAuthenticated =
    typeof auth.isAuthenticated === "string"
      ? parseLocalStorageValue(auth.isAuthenticated)
      : auth.isAuthenticated;

  const hasAcceptedTerms =
    typeof auth.hasAcceptedTerms === "string"
      ? parseLocalStorageValue(auth.hasAcceptedTerms)
      : auth.hasAcceptedTerms;

  const hasRequiredPermission = requiredPermission == null ||
    auth.permissions.includes("*") || auth.permissions.includes(requiredPermission);

  return isAuthenticated ? (
    hasRequiredPermission ? (
      hasAcceptedTerms ? (
        children
      ) : (
        <Navigate to="/terms-and-conditions/accept"/>
      )) : (
      <Navigate to="/forbidden"/>
    )
  ) : (
    <Navigate to="/login"/>
  );
};

export default PrivateRoute;
