import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetTms } from "../../../actions/tmsActions";
import { Button } from "primereact/button";
import _ from "lodash";
import { useParams } from "react-router-dom";

const TmsDetail = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const tmsState = useSelector((state) => state.tms);

  React.useEffect(() => {
    dispatch(GetTms(id));
  }, [dispatch, id]);

  const getDetails = (id) => {
    if (!_.isEmpty(tmsState.data[id])) {
      const tms = tmsState.data[id];
      return (
        <>
          <div>
            <img
              src={`${tms.logo}`}
              onError={(e) =>
                (e.target.src =
                  "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
              }
              alt={tms.name}
              height="100"
            />
          </div>
          <div className="grid grid-nogutter border-top-1 surface-border pt-2">
            <div className="col-12 md:col-6 p-3">
              <div className="text-500 font-medium mb-2">Name</div>
              <div className="text-900">{tms.name}</div>
            </div>
            <div className="col-12 p-3">
              <div className="text-500 font-medium mb-2">Description</div>
              <div className="text-900 line-height-3">{tms.description}</div>
            </div>
            {/*<div className="col-12 p-3">*/}
            {/*    <div className="text-500 font-medium mb-2">Website</div>*/}
            {/*    <div className="text-900 line-height-3"><a className="mtg-link" href={tms.website} target="_blank">{tms.website}</a></div>*/}
            {/*</div>*/}
            {/*<div className="col-12 p-3">*/}
            {/*    <div className="text-500 font-medium mb-2">Enabled</div>*/}
            {/*    <div className="text-900 line-height-3">*/}
            {/*        <InputSwitch checked={tms.enabled} />*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="col-12 p-3">
              <div className="text-500 font-medium mb-3">Instructions</div>
              <div className="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row">
                <div className="flex align-items-center">
                  <span className="block pi pi-file mr-2"></span>
                  <span className="text-900">
                    {tms.instructions
                      ? tms.instructions.split("/").slice(-1)
                      : "N/A"}
                    {}
                  </span>
                </div>
                <Button
                  icon="pi pi-download"
                  label="Download"
                  className="p-button-outlined mt-2 md:mt-0"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = tms.instructions;
                  }}
                ></Button>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return <div>{getDetails(id)}</div>;
};

export default TmsDetail;
