import axios from "axios";
import { baseUrl, clientId, clientSecret } from "../constants/axiosConf";
import api from "./api";

const onRequest = (config) => {
  const token = localStorage.getItem("access");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

const onResponseError = async (error) => {
  if (error.response) {
    // access token expired
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.response.data.detail.startsWith("Invalid token header.") &&
      !originalRequest._retry
    ) {
      const originalRequest = error.config;
      const refresh = localStorage.getItem("refresh");

      try {
        // Don't use api here, it will cause an infinite loop, call directly
        const rs = await axios.post(`${baseUrl}/auth/token`, {
          grant_type: "refresh_token",
          refresh_token: refresh,
          client_id: clientId,
          client_secret: clientSecret,
        });

        const { access_token, refresh_token } = rs.data;

        localStorage.setItem("access", access_token);
        localStorage.setItem("refresh", refresh_token);
        localStorage.setItem("isAuthenticated", "true");
        // assume user has accepted terms and conditions,
        // if they haven't, they will have to accept them on the next login
        localStorage.setItem("hasAcceptedTerms", "true");

        // Retry the request
        originalRequest._retry = true;
        return api(originalRequest);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
  }
  return Promise.reject(error);
};

export const setupInterceptorsTo = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
