import { ConfigurationActionTypes } from "../constants/configuration-action-types";

const DefaultState = {
  loading: false,
  data: [],
  errorMsg: "",
  count: 0,
  rows: 0,
  page: 1,
  offset: 0,
  filter: {},
};

const ConfigurationListReducer = (state = DefaultState, action) => {
  switch (action.type) {
    case ConfigurationActionTypes.CONFIGURATION_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case ConfigurationActionTypes.CONFIGURATION_LIST_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: "Unable to fetch configurations",
        count: 0,
        rows: 0,
      };
    case ConfigurationActionTypes.CONFIGURATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.results,
        errorMsg: "",
        count: action.payload.count,
        rows: action.rows,
        offset: action.offset,
        filter: action.filter,
      };
    default:
      return state;
  }
};

export default ConfigurationListReducer;
