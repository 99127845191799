import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteConfiguration,
  GetConfigurationsList,
} from "../../actions/configurationActions";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useNavigate } from "react-router-dom";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { Toast } from "primereact/toast";
import { Message } from "primereact/message";

const ConfigurationList = () => {
  const dispatch = useDispatch();
  const configurations = useSelector((state) => state.configurationList);
  const navigate = useNavigate();
  const toast = useRef(null);

  const rowsPerPageOptions = [5, 10, 50];
  const rows = rowsPerPageOptions[0];

  // Initial load
  React.useEffect(() => {
    dispatch(GetConfigurationsList(rows, configurations.page, 0, ""));
  }, [dispatch]);

  const onPage = (event) => {
    dispatch(
      GetConfigurationsList(
        event.rows,
        event.page,
        event.first,
        configurations.filter,
      ),
    );
  };

  const onFilter = (event) => {
    dispatch(
      GetConfigurationsList(
        configurations.rows,
        configurations.page,
        configurations.first,
        event.filters,
      ),
    );
  };

  const confirmDeleteDoc = async (event) => {
    await new Promise(async (resolve) => {
      confirmPopup({
        target: event.currentTarget,
        message: "Are you sure you wish to delete this configuration?",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          await dispatch(DeleteConfiguration(event));

          // Update the configurations after deleting
          dispatch(
            GetConfigurationsList(
              rows,
              configurations.page,
              configurations.offset,
              configurations.filter,
            ),
          );

          toast.current.show({
            severity: "success",
            summary: "Deleted",
            detail: "Configuration has been deleted.",
          });

          resolve(); // Resolve the Promise when everything is done
        },
        reject: () => {
          resolve(); // Resolve the Promise even if the user cancels the action
        },
      });
    });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="grid" style={{ minWidth: "10rem" }}>
          <Button
            icon="pi pi-eye"
            className="p-button-rounded p-button-info p-mr-2 col-4"
            onClick={() => navigate(`/config/${rowData.id}`)}
            tooltip="View credentials"
          />
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-warning p-mr-2 margin-left col-4"
            onClick={() => navigate(`/config/${rowData.id}/edit`)}
            tooltip="Edit"
          />
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger margin-left col-4"
            onClick={() => confirmDeleteDoc(rowData.id)}
            tooltip="Delete"
          />
        </div>
      </React.Fragment>
    );
  };

  const enabledBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Message
          style={{ minWidth: "120px" }}
          severity={rowData.enabled ? "success" : "error"}
          text={rowData.enabled ? "Active" : "Disabled"}
        />
      </React.Fragment>
    );
  };

  const titleBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <button
          onClick={() => navigate(`/config/${rowData.id}`)}
          className="btn-link"
        >
          {rowData.name}
        </button>
      </React.Fragment>
    );
  };

  return (
    <div>
      <div className="flex justify-content-between flex-wrap">
        <div className="flex">
          <h1>Manage configurations</h1>
        </div>
      </div>
      <Button
        className="mtg-input mb-2"
        label="New configuration"
        onClick={() => navigate("/config/create")}
      />
      <DataTable
        value={configurations.data}
        lazy
        totalRecords={configurations.count}
        rows={configurations.rows}
        first={configurations.offset}
        onPage={onPage}
        onFilter={onFilter}
        loading={configurations.loading}
        emptyMessage="No configurations found."
        filterDisplay="row"
        globalFilterFields={["name"]}
        paginator
        responsiveLayout="scroll"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} configurations"
        rowsPerPageOptions={rowsPerPageOptions}
      >
        <Column
          field="name"
          header="Name"
          body={titleBodyTemplate}
          filter
          filterPlaceholder="Search by name"
          showFilterMatchModes={false}
        />

        <Column
          field="enabled"
          body={enabledBodyTemplate}
          style={{ width: "9rem" }}
          header="Enabled"
        />
        <Column
          header="Actions"
          body={actionBodyTemplate}
          exportable={false}
          style={{ width: "12rem" }}
        />
      </DataTable>
      <div className="p-3 col justify-content-center align-content-center mtg-input"></div>
      <Toast ref={toast} />
      <ConfirmPopup />
    </div>
  );
};

export default ConfigurationList;
