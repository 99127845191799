import { ProviderActionTypes } from "../constants/provider-action-types";
import api from "../interceptors/api";

export const GetProviderList = () => async (dispatch) => {
  try {
    dispatch({
      type: ProviderActionTypes.PROVIDER_LIST_LOADING,
    });

    const res = await api.get(`/providers/api/providers`);

    dispatch({
      type: ProviderActionTypes.PROVIDER_LIST_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: ProviderActionTypes.PROVIDER_LIST_FAIL,
    });
  }
};

export const GetProvider = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ProviderActionTypes.PROVIDER_MULTIPLE_LOADING,
    });

    const res = await api.get(`/providers/api/provider/${id}`);

    dispatch({
      type: ProviderActionTypes.PROVIDER_MULTIPLE_SUCCESS,
      payload: res.data,
      providerId: id,
    });
  } catch (e) {
    dispatch({
      type: ProviderActionTypes.PROVIDER_MULTIPLE_FAIL,
    });
  }
};

export const ChangeProviderEnabled = (id, value) => async (dispatch) => {
  try {
    dispatch({
      type: ProviderActionTypes.PROVIDER_CHANGE_ENABLED_LOADING,
    });

    const data = {
      enabled: value,
    };

    const res = await api.patch(`/providers/api/provider/${id}`, data);

    dispatch({
      type: ProviderActionTypes.PROVIDER_CHANGE_ENABLED_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: ProviderActionTypes.PROVIDER_CHANGE_ENABLED_FAILED,
    });
  }
};
