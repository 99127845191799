/**
 * File upload has some quirks. This is a helper function to make it easier.
 */

export const uploadedFilePreprocessor = (file) => {
  // Copy the file to avoid the "file is locked" error
  const uploadedFile = new File([file], file.name, {
    type: file.type,
  });

  return uploadedFile;
};
