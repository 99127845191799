import { EngineActionTypes } from "../constants/engine-action-types";
import api from "../interceptors/api";

export const GetEnginesList =
  (rows, page, offset, query, website, all, none) => async (dispatch) => {
    try {
      let formData = new FormData();
      for (let key in query) {
        // Transform Query of form {<key>: {'value': <value>}}
        // to FormData of form {<key>: <value>}
        let queryValue = query[key].value;
        if (queryValue) {
          formData.append(key, queryValue);
        }
      }
      if (all) {
        formData.append("all", all);
      }
      if (none) {
        formData.append("none", none);
        formData.delete("all");
      }

      dispatch({
        type: EngineActionTypes.ENGINE_LIST_LOADING,
        query: query,
      });

      if (website === undefined) {
        website = "";
      }

      // const offset = (page * rows) - rows;
      const res = await api.post(
        `/catalogue/api/engines/search?rows=${rows}&offset=${offset}`,
        formData,
      );

      dispatch({
        type: EngineActionTypes.ENGINE_LIST_SUCCESS,
        payload: res.data,
        rows: rows,
        offset: offset,
        query: query,
        all: all,
      });
    } catch (e) {
      dispatch({
        type: EngineActionTypes.ENGINE_LIST_FAIL,
      });
    }
  };

export const GetFirstAvailableEngine = (query) => async (dispatch) => {
  try {
    const config = {
      params: {
        query: query,
        all: true,
      },
    };
    dispatch({
      type: EngineActionTypes.FIRST_AVAILABLE_ENGINE_LOADING,
      query: query,
    });

    const res = await api.post(
      `/catalogue/api/engines/search?rows=1&offset=0`,
      config,
    );

    dispatch({
      type: EngineActionTypes.FIRST_AVAILABLE_ENGINE_SUCCESS,
      payload: res.data,
      query: query,
    });
  } catch (e) {
    dispatch({
      type: EngineActionTypes.FIRST_AVAILABLE_ENGINE_FAIL,
    });
  }
};

export const GetAvailableSourceLanguages = (testsetId) => async (dispatch) => {
  try {
    dispatch({
      type: EngineActionTypes.AVAILABLE_SOURCE_LANGUAGES_LOADING,
    });

    let url = `/catalogue/api/source-languages`;
    if (testsetId) {
      url = `/catalogue/api/source-languages?testsetId=${testsetId}`;
    }
    const res = await api.get(url);

    dispatch({
      type: EngineActionTypes.AVAILABLE_SOURCE_LANGUAGES_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: EngineActionTypes.AVAILABLE_SOURCE_LANGUAGES_FAIL,
    });
  }
};

export const GetAvailableTargetLanguages =
  (testsetId, source) => async (dispatch) => {
    try {
      dispatch({
        type: EngineActionTypes.AVAILABLE_TARGET_LANGUAGES_LOADING,
      });
      let url = `/catalogue/api/target-languages?testsetId=${testsetId}&source=${source}`;
      if (!testsetId) {
        url = `/catalogue/api/target-languages?source=${source}`;
      } else if (!source) {
        url = `/catalogue/api/target-languages?testsetId=${testsetId}`;
      }
      const res = await api.get(url);

      dispatch({
        type: EngineActionTypes.AVAILABLE_TARGET_LANGUAGES_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: EngineActionTypes.AVAILABLE_TARGET_LANGUAGES_FAIL,
      });
    }
  };

export const ResetAvailableTargetLanguages = () => async (dispatch) => {
  dispatch({
    type: EngineActionTypes.AVAILABLE_TARGET_LANGUAGES_RESET,
  });
};

/**
 * Fetch available domains.
 * @param {string} source - Source language filter.
 * @param {string} target - Target language filter.
 * @param {string|null} provider - Provider filter.
 * @param {boolean} unavailable - If true, show (disabled) domains that are not available for the selected provider.
 */
export const GetAvailableDomains = (
  source,
  target,
  provider = null,
  unavailable = false,
) => {
  // Function to build the query string for API request
  const buildQueryString = (source, target, provider) => {
    const params = new URLSearchParams({ source, target });

    if (provider !== null) {
      params.set("provider", provider);
    }

    return params.toString();
  };

  // Function to fetch data domains from the API
  const getDataDomains = async (source, target, provider = null) => {
    const queryString = buildQueryString(source, target, provider);
    return (await api.get(`/catalogue/api/domains?${queryString}`)).data;
  };

  return async (dispatch) => {
    try {
      dispatch({
        type: EngineActionTypes.AVAILABLE_DOMAINS_LOADING,
      });

      // Fetch data domains based on the provided filters
      const data = await getDataDomains(source, target, provider);

      if (unavailable) {
        // Fetch data domains without a specific provider
        const dataNoProvider = await getDataDomains(source, target);
        const existingDomainValues = new Set(data.map((d) => d.value));

        // Add 'disabled' domains to data which were only found in dataNoProvider
        dataNoProvider.forEach((domain) => {
          if (!existingDomainValues.has(domain.value)) {
            domain.disabled = true;
            data.push(domain);
          }
        });
      }

      dispatch({
        type: EngineActionTypes.AVAILABLE_DOMAINS_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: EngineActionTypes.AVAILABLE_DOMAINS_FAIL,
      });
    }
  };
};

export const ResetAvailableDomains = () => async (dispatch) => {
  dispatch({
    type: EngineActionTypes.AVAILABLE_DOMAINS_RESET,
  });
};

/**
 * Fetch available providers.
 * @param {string} source - Source language filter.
 * @param {string} target - Target language filter.
 * @param {string|null} domain - Domain filter.
 * @param {boolean} unavailable - If true, show (disabled) providers that are not available for the selected domain.
 */
export const GetAvailableProviders = (
  source,
  target,
  domain = null,
  unavailable = false,
) => {
  // Function to build the query string for API request
  const buildQueryString = (source, target, domain = null) => {
    const params = new URLSearchParams({ source, target });

    if (domain !== null) {
      params.set("domain", domain);
    }

    return params.toString();
  };

  // Function to fetch data providers from the API
  const getDataProviders = async (source, target, domain = null) => {
    const queryString = buildQueryString(source, target, domain);

    return (await api.get(`/catalogue/api/providers?${queryString}`)).data;
  };

  return async (dispatch) => {
    try {
      dispatch({
        type: EngineActionTypes.AVAILABLE_PROVIDERS_LOADING,
      });

      // Fetch data providers based on the provided filters
      const data = await getDataProviders(source, target, domain);

      if (unavailable) {
        // Fetch data providers without a specific domain
        const dataNoProvider = await getDataProviders(source, target, null);
        const existingProviderValues = new Set(data.map((d) => d.value));

        // Add 'disabled' engines to data which were only found in data_no_domain
        dataNoProvider.forEach((provider) => {
          if (!existingProviderValues.has(provider.value)) {
            provider.disabled = true;
            data.push(provider);
          }
        });
      }

      dispatch({
        type: EngineActionTypes.AVAILABLE_PROVIDERS_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: EngineActionTypes.AVAILABLE_PROVIDERS_FAIL,
      });
    }
  };
};

export const ResetAvailableProviders = () => async (dispatch) => {
  dispatch({
    type: EngineActionTypes.AVAILABLE_PROVIDERS_RESET,
  });
};

export const GetAvailableTags = (source, target) => async (dispatch) => {
  try {
    dispatch({
      type: EngineActionTypes.AVAILABLE_TAGS_LOADING,
    });

    const res = await api.get(
      `/catalogue/api/tags?source=${source}&target=${target}`,
    );

    dispatch({
      type: EngineActionTypes.AVAILABLE_TAGS_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: EngineActionTypes.AVAILABLE_TAGS_FAIL,
    });
  }
};

export const setLastEngine = (
  sourceLanguage,
  targetLanguage,
  provider,
  domain,
  detectLanguage,
) => ({
  type: EngineActionTypes.SET_LAST_ENGINE,
  payload: { sourceLanguage, targetLanguage, provider, domain, detectLanguage },
});
