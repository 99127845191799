import React from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
      <div className="font-bold text-6xl text-center mb-4 primary-color">
        Select Advisory Type
      </div>

      <div className="flex flex-column md:flex-row mt-6">
        <div className="mb-6 md:mb-0 mr-0 md:mr-4 border-1 border-gray-400 flex flex-column flex-grow-1">
          <div className="text-center p-3">
            <div className="text-2xl font-bold mb-3">Automated</div>
            <div className="primary-color flex align-items-center justify-content-center">
              <span className="font-bold mr-2 custom-engine-advisory-pricing-title price">
                € 19.99
              </span>
              <span className="font-bold custom-engine-advisory-pricing-title per-advisory">
                {" "}
                / advisory
              </span>
            </div>
          </div>
          <div className="p-4 flex flex-column flex-grow-1">
            <div className="font-bold line-height-3 mb-4">
              Run a test set of a maximum of 1000 segments through up to 10
              selected engines. The Automated Engine Advisory is billed at:
            </div>
            <ul className="list-none p-0 m-0 mb-5">
              <li className="flex align-items-center mb-3">
                <i className="pi pi-circle-on mr-2 text-sm"></i>
                <span>€ 19.99 without subscription, and</span>
              </li>
              <li className="flex align-items-center mb-3">
                <i className="pi pi-circle-on mr-2 text-sm"></i>
                <span>€ 9.99 with subscription</span>
              </li>
            </ul>

            <Button
              className="p-button w-full mt-auto border-none"
              label="Use Now"
              onClick={() => navigate(`/engine-advisory/automated/new`)}
            />

            <Button
              className="mt-2 p-button p-button-outlined w-full"
              label="Previous Advisories"
              onClick={() => navigate(`/engine-advisory/automated`)}
            />
          </div>
        </div>

        <div className="mb-6 md:mb-0 border-1 border-gray-400 flex flex-column flex-grow-1">
          <div
            className="text-center p-3"
            style={{ borderRadius: "6px 6px 0 0" }}
          >
            <div className="text-2xl font-bold mb-3">Professional</div>
            <div className="primary-color flex align-items-center justify-content-center">
              <span className="font-bold mr-2 custom-engine-advisory-pricing-title price">
                € 399
              </span>
              <span className="font-bold custom-engine-advisory-pricing-title per-advisory">
                {" "}
                / advisory
              </span>
            </div>
          </div>
          <div className="p-4 flex flex-column flex-grow-1">
            <div className="font-bold line-height-3 mb-4">
              Provided by CrossLang specialists. The basic service is based on
              the automated analysis with human review, where more extended
              options can see additions such as productivity measurements, etc.
            </div>

            <Button
              className="p-button-outlined w-full mt-auto"
              label="Contact Us"
              onClick={() => navigate(`/engine-advisory/contact`)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
