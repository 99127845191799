import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dropdown } from "primereact/dropdown";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import api from "../../interceptors/api";
import { Toast } from "primereact/toast";

const AddToConfiguration = (props) => {
  const rowData = props.rowData;
  const engine = rowData.engine;
  // available configurations
  const configurations = useSelector((state) => state.configurationList);
  const [selectedConfigurationId, setSelectedConfigurationId] = useState(null);

  const configOverlayRef = useRef(null);
  const toast = useRef(null);

  const addToConfigurationClick = (e) => {
    configOverlayRef.current.toggle(e);
  };

  const saveEngineToConfiguration = async () => {
    try {
      const res = await api.post(
        `/catalogue/api/add-engine-config/${selectedConfigurationId}`,
        {
          engineId: engine.id,
        }
      );
      if (res.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Engine added",
          detail:
            "The engine has been successfully added to your configuration.",
        });
        configOverlayRef.current.hide();
      }
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        toast.current.show({
          severity: "error",
          summary: "Not authorized",
          detail:
            "You are not authorized to add engines to this configuration.",
        });
      } else if (err.response.status === 404) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Configuration not found.",
        });
      } else if (err.response.status === 409) {
        toast.current.show({
          severity: "info",
          summary: "Already selected",
          detail:
            "This engine has already been added to the chosen configuration.",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "An error occurred while adding engine to configuration.",
        });
      }
    }
  };

  return (
    <>
      <Button
        label="Add"
        icon="pi pi-plus"
        className="p-button-sm"
        onClick={addToConfigurationClick}
      />
      <OverlayPanel
        ref={configOverlayRef}
        showCloseIcon={true}
        className="add-to-config"
      >
        <div className="flex flex-row justify-content-end">
          <Dropdown
            options={configurations.data.map((config) => ({
              label: config.name,
              value: config.id,
            }))}
            panelClassName="add-to-config"
            value={selectedConfigurationId}
            onChange={(event) => setSelectedConfigurationId(event.value)}
            placeholder="Select a configuration"
          />
          <Button
            label="Confirm"
            icon="pi pi-check"
            className="p-button-success ml-2 p-button-sm"
            onClick={saveEngineToConfiguration}
            disabled={!selectedConfigurationId}
          />
        </div>
      </OverlayPanel>
      <Toast ref={toast} />
    </>
  );
};

export default React.memo(AddToConfiguration);
